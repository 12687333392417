import { ListSubheader } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import TextInput from 'components/TextInput/TextInput';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsCreatingOrUpdatingTags } from 'store/mediaTags/selectors/mediaTags.selectors';

export const MediaTagAutocomplete = ({
  mediaTagInputValue,
  mediaTagOptions,
  handleSelectMediaTag,
  setMediaTagInputValue,
}) => {
  const isCreatingOrUpdatingMediaTags = useSelector(
    selectIsCreatingOrUpdatingTags
  );

  const { t } = useTranslation('common');

  return (
    <Autocomplete
      noOptionsText={''}
      disableClearable
      fullWidth
      groupBy={(option) => option.groupIdentifier}
      getOptionLabel={(option) =>
        option.name
          ? `${
              option.assignmentNumberPath ? option.assignmentNumberPath : ''
            } ${option.name}`
          : ''
      }
      options={mediaTagOptions}
      inputValue={mediaTagInputValue}
      onChange={(e, value) => handleSelectMediaTag(value)}
      disabled={isCreatingOrUpdatingMediaTags}
      renderInput={(params) => (
        <TextInput
          {...params}
          fullWidth
          label={t('gallery.tag')}
          onChange={(event) => setMediaTagInputValue(event.target.value)}
        />
      )}
      renderGroup={(params) => (
        <li key={params.key} style={{ padding: 0, margin: 0 }}>
          <ListSubheader
            style={{
              background: '#0b7197',
              padding: '0px 0px 0px 4px',
              margin: 0,
            }}
          >
            {params.group}
          </ListSubheader>
          <ul style={{ padding: 0 }}>{params.children}</ul>
        </li>
      )}
    />
  );
};
