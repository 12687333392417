import { createAsyncThunk } from '@reduxjs/toolkit';

import { updateUserEmployerBusinessIdResources } from 'store/userProfile/services/update-user-employer-business-id-resources.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const setUpdateUserEmployerBusinessIdResources = createAsyncThunk(
  'users/setUpdateUserEmployerBusinessIdResources',
  async (params, { getState }) => {
    const state = getState();
    const selectedProjectId = selectSelectedProjectId(state);

    const _params = {
      ...params,
      projectId: selectedProjectId
    }

    const updatedResource = await updateUserEmployerBusinessIdResources(_params);

    return updatedResource;
  }
);