import { mdiTagPlus } from '@mdi/js';
import Icon from '@mdi/react';
import Button from 'components/Button/Button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageSelectMediaTagInput } from './ImageSelectMediaTagInput/ImageSelectMediaTagInput';

export const ImageSelectAddMediaTag = ({ fileIndex, projectId }) => {
  const [isMediaTagInputActive, setIsMediaTagInputActive] = useState();

  const { t } = useTranslation('common');

  return (
    <div>
      {!isMediaTagInputActive ? (
        <Button elevated onClick={() => setIsMediaTagInputActive(true)}>
          <Icon path={mdiTagPlus} size={1}></Icon>
        </Button>
      ) : (
        <ImageSelectMediaTagInput
          fileIndex={fileIndex}
          projectId={projectId}
        ></ImageSelectMediaTagInput>
      )}
    </div>
  );
};
