import { createAsyncThunk } from '@reduxjs/toolkit';
import getProjectsOfBusiness from '../services/getProjectsOfBusiness.service';
import { selectSelectedProject } from '../selectors/projectsSelectors';

export const loadAllBusinessIdProjects = createAsyncThunk(
  'projects/loadAllBusinessIdProjects',
  async (_, { getState }) => {
    const selectedProject = selectSelectedProject(getState());
    let projectsOfBusiness = [];

    let response;

    do {
      response = await getProjectsOfBusiness(selectedProject?.business_id, selectedProject?.id, response?.LastEvaluatedKey);
      projectsOfBusiness.push(...response.Items);
    } while (response.LastEvaluatedKey);

    const projects = [].concat(...projectsOfBusiness);

    return projects;
  }
);
