import { createAction } from '@reduxjs/toolkit';

export const setInitialProjectKeyToJoin = createAction(
  'projectJoin/setInitialProjectKeyToJoin'
);

export const initProjectJoin = createAction('projectJoin/initProjectJoin');

export const clearProjectsOfBusiness = createAction(
  'projectJoin/clearProjectsOfBusiness'
);