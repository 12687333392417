import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCustomersByBusinessId } from '../services/getCustomersByBusinessId.service';

export const loadCustomersByBusinessId = createAsyncThunk(
  'customers/loadCustomersByBusinessId',
  async (businessId) => {
    const response = await getCustomersByBusinessId(businessId);

    return response;
  }
);
