import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { selectSelectedProjectResource } from 'scenes/ProjectResources/store/selectors/project-resources.selectors';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';

const selectUserProfileState = (state) => state.userProfile;

const selectCurrentUserState = (state) => state.user;

export const selectUserProfileUser = createSelector(
  [selectUserProfileState],
  (state) => state.user
);

export const selectUserProfileUserEmployerResources = createSelector(
  [selectUserProfileState],
  (state) => state.userEmployerResources
);

export const selectCorrectEmployerResource = createSelector(
  [selectUserProfileUserEmployerResources, selectSelectedProject, selectSelectedProjectResource],
  (userEmployerResources, { business_id }, { employerBusinessId, id }) => {
    if (!employerBusinessId) return userEmployerResources; // if employerBusinessId is not provided, return all resources
    const resource = userEmployerResources[`${employerBusinessId}_${business_id}`];

    if (resource) {
      if (id) {
        const tempResource = { ...resource, selectedResourceId: id };
        return tempResource;
      }
      return resource;
    }

  }
);

export const selectIsUserProfileLoading = createSelector(
  [selectUserProfileState],
  (state) => state.loading
);

export const selectUserProfileEmployerBusinessIds = createSelector(
  [selectUserProfileState],
  (state) => state.userEmployerBusinessIds
);

export const selectUserProfileEmployeeConfigurations = createSelector(
  [selectUserProfileState],
  (state) => Object.values(state.userEmployeeConfigurations)
);

export const selectCurrentUser = createSelector(
  [selectCurrentUserState],
  (state) => state.user
);

export const selectUserEmployeeConfigurationOfEmployer = createSelector(
  [selectUserProfileEmployeeConfigurations, (_, businessId) => businessId],
  (configurations, businessId) => {
    console.log("configurations:", configurations)
    const foundConfiguration = configurations.find(
      (configuration) => configuration.businessId === businessId
    );

    return foundConfiguration ? foundConfiguration : {};
  }
);
