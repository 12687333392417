import { createAsyncThunk } from '@reduxjs/toolkit';
import { VIEW_OTHER_RESOURCES } from 'scenes/Permissions/constants/permissions';
import {
  selectIsAdminInSelectedProject,
  selectSelectedProjectUserPermissions,
} from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';
import { loadAllUserProjectsPermissions } from 'scenes/Permissions/store/thunks/loadAllUserProjectsPermissions.thunk';
import { setSelectedProjectId } from 'scenes/Project/store/actions/projectActions';
import { loadMediaFilePermissionsByUserAndProject } from 'scenes/ProjectGallery/store/thunks/loadMediaFilePermissionsByUserAndProject';
import { setProject } from 'store/actions/setProject';
import { loadAssignmentsForProject } from 'store/assignments/thunks/loadAssignmentsForProject';
import { loadInteractionsByProject } from 'store/interactions/thunks/loadInteractionsByProject';
import { loadProjectMediaTagsData } from 'store/mediaTags/thunks/loadProjectMediaTagsData.thunk';
import { loadResourcesOfProject } from 'store/resources/thunks/loadResourcesOfProject';

export const initProjectGallery = createAsyncThunk(
  'projectGallery/initProjectGallery',
  async (projectId, { dispatch, getState }) => {
    dispatch(setProject({ id: projectId }));
    dispatch(setSelectedProjectId(projectId));

    await dispatch(loadAllUserProjectsPermissions());

    dispatch(loadProjectMediaTagsData(projectId));

    dispatch(loadAssignmentsForProject(projectId));

    const permissions = selectSelectedProjectUserPermissions(getState());
    const isAdmin = selectIsAdminInSelectedProject(getState());
    if (permissions[VIEW_OTHER_RESOURCES] || isAdmin) {
      dispatch(loadResourcesOfProject(projectId));
    }

    await dispatch(loadMediaFilePermissionsByUserAndProject({ projectId }));
    dispatch(loadInteractionsByProject(projectId));
  }
);
