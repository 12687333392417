import { createAsyncThunk } from '@reduxjs/toolkit';
import { patchUpdateShortTermProjectStatus } from '../services/patchUpdateShortTermProjectStatus.service';

export const updateShortTermProjectStatus = createAsyncThunk(
  'shortTermProjects/updateShortTermProjectStatus',
  async (updateData) => {
    const { projectId, value } = updateData;
    const response = await patchUpdateShortTermProjectStatus(projectId, value);

    return response;
  }
);
