import React, { useState } from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Dialog from 'components/DialogWrapper/DialogWrapper';

import Button from 'components/Button/Button';

import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { mdiLogoutVariant } from '@mdi/js';
import Icon from '@mdi/react';
import DialogHeader from 'components/DialogHeader/DialogHeader';
import useStyles from 'components/RecordDialog/RecordDialogLayoutStyles';

import ButtonLoading from 'components/ButtonLoading/ButtonLoading';
import { ExpiringCardsNotification } from 'components/ExpiringCardsNotification/ExpiringCardsNotification';
import { ExpiringEmployeeCardsNotification } from 'components/ExpiringCardsNotification/ExpiringEmployeeCardsNotification';
import UploadProgressDialog from 'components/addFiles/UploadProgressDialog';
import CalendarRemoveIcon from 'mdi-material-ui/CalendarRemove';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveLoggedInUserAdminBusinessNotifications,
  selectActiveLoggedInUserNotifications,
} from 'store/notifications/selectors/notifications/user-notifications.selectors';
import { closeClockOutRecordDialog } from 'store/records/actions/records.actions';
import {
  selectIsClockOutPending,
  selectSelectedRecordProject,
  selectedClockOutRecordWithUpdatedAttributes,
} from 'store/records/selectors/records.selectors';
import { clockOutRecord } from 'store/records/thunks/clockOutRecord.thunk';
import { deleteRecordThunk } from 'store/records/thunks/deleteRecordThunk';
import { distributeAllowanceOperationsByAllowancesCreateParams } from 'store/records/thunks/distributeAllowanceOperationsByAllowancesCreateParams';
import { capitalize } from 'utils/helpers';
import { ClockOutInputs } from './components/ClockOutInputs';
import LocationInfo from './components/LocationInfo';
import WorkTimesContainer from './components/WorkTimesContainer';
import { RecordsTimelineContainer } from './components/refreshComponents/RecordsTimelineContainer';
import useProjectImages from './hooks/useProjectImages';

function ClockOutRecordDialog({ open }) {
  const theme = useTheme();

  const classes = useStyles();

  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const closeDialog = () => {
    dispatch(closeClockOutRecordDialog());
  };

  const currentDate = moment();

  const currentDayName = capitalize(currentDate.format('dddd'));
  const currentDateFormated = currentDate.format('DD.MM.YYYY');

  const selectedRecordProject = useSelector(selectSelectedRecordProject);
  const clockOutRecordWithUpdatedAttributes = useSelector(
    selectedClockOutRecordWithUpdatedAttributes
  );

  const cardsAlarmNotifications = useSelector(
    selectActiveLoggedInUserNotifications
  );

  const ownEmployeeCardAlarmNotifications = useSelector(
    selectActiveLoggedInUserAdminBusinessNotifications
  );

  const projectName =
    selectedRecordProject && selectedRecordProject.name
      ? selectedRecordProject.name
      : '';
  const projectKey =
    selectedRecordProject && selectedRecordProject.projectKey
      ? selectedRecordProject.projectKey
      : '';

  const {
    filesCount,
    filesUploadingCount,
    uploadDialogOpen,
    uploadProgress,
    uploadSizes,
    onUploadImages,
    setImageFiles,
    onCancelAdding,
  } = useProjectImages();

  const isClockOutPending = useSelector(selectIsClockOutPending);

  const handleClockOut = async () => {
    dispatch(distributeAllowanceOperationsByAllowancesCreateParams());
    await onUploadImages({
      recordId: clockOutRecordWithUpdatedAttributes.id,
      assignmentId: clockOutRecordWithUpdatedAttributes.assignment,
      projectId: selectedRecordProject.id,
    });
    dispatch(clockOutRecord());
  };

  const calculateDifferenceTimeInMinutes = (record) => {
    const clockIn = moment(record.clockInAt);
    const clockOut = moment(record.clockOutAt);

    return clockOut.diff(clockIn, 'minutes');
  };

  const timeDiffLimit = 5;

  const isTimeDiffUnderMinutes =
    calculateDifferenceTimeInMinutes(clockOutRecordWithUpdatedAttributes) <
    timeDiffLimit;

  const [timeDifferenceDeleteSuggest, setTimeDifferenceDeleteSuggest] =
    useState(isTimeDiffUnderMinutes);

  const handleRecordDelete = (record) => {
    dispatch(
      deleteRecordThunk({ projectId: record.project, recordId: record.id })
    );
    dispatch(closeClockOutRecordDialog());
  };

  return (
    <>
      <Dialog
        aria-labelledby="clock-out-record-dialog"
        classes={{ paper: classes.dialogPaper }}
        disableBackdropClick
        fullScreen={!upSm}
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={closeDialog}
      >
        <DialogHeader
          icon={
            <Icon path={mdiLogoutVariant} size={1} className={classes.icon} />
          }
          onClose={closeDialog}
          title={t('record.clock_out')}
        />
        <DialogContent
          style={{
            backgroundImage: "url('/images/background_lixani.jpg')",
            backgroundSize: 'cover',
          }}
        >
          <Grid container spacing={2}>
            {cardsAlarmNotifications && cardsAlarmNotifications.length > 0
              ? cardsAlarmNotifications.map((notification) => (
                  <Grid item xs={12} key={notification.id}>
                    <ExpiringCardsNotification
                      expiringCards={notification.expiringCardsInfo}
                      cardsValidUntil={notification.cardsValidUntil}
                      notificationId={notification.id}
                    ></ExpiringCardsNotification>
                  </Grid>
                ))
              : null}
            {ownEmployeeCardAlarmNotifications &&
            ownEmployeeCardAlarmNotifications.length > 0
              ? ownEmployeeCardAlarmNotifications.map((notification) => (
                  <Grid item xs={12} key={notification.id}>
                    <ExpiringEmployeeCardsNotification
                      expiringCards={notification.expiringCardsInfo}
                      cardsValidUntil={notification.cardsValidUntil}
                      notificationId={notification.id}
                    ></ExpiringEmployeeCardsNotification>
                  </Grid>
                ))
              : null}
            <Grid item xs={12}>
              <Typography variant="subtitle2" align="center">
                {currentDayName} {currentDateFormated}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                align="center"
                style={{ fontWeight: 'bold' }}
              >
                {projectName}
              </Typography>
              <Typography variant="subtitle2" align="center">
                {projectKey}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <RecordsTimelineContainer
                currentDate={currentDate}
              ></RecordsTimelineContainer>
            </Grid>
            <Grid item xs={12}>
              <WorkTimesContainer></WorkTimesContainer>
            </Grid>

            <Grid item xs={12}>
              <LocationInfo
                record={clockOutRecordWithUpdatedAttributes}
              ></LocationInfo>
            </Grid>

            <Grid item xs={12}>
              <ClockOutInputs
                isClockOutPending={isClockOutPending}
                projectId={selectedRecordProject.id}
                projectType={selectedRecordProject.type}
                setImageFiles={setImageFiles}
              ></ClockOutInputs>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.actionBtn}
            onClick={closeDialog}
            outline
            color="primary"
            disabled={isClockOutPending}
          >
            {t('common.cancel')}
          </Button>
          <Button
            className={classes.actionBtn}
            onClick={() => handleClockOut()}
            color="primary"
            disabled={isClockOutPending}
          >
            {!isClockOutPending ? t('record.clock_out') : <ButtonLoading />}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="clock-out-delete-suggest-dialog"
        classes={{ paper: classes.dialogPaper }}
        fullWidth
        maxWidth="xs"
        open={timeDifferenceDeleteSuggest}
      >
        <DialogHeader
          icon={<CalendarRemoveIcon />}
          title={t('calendar.remove_record')}
        />
        <DialogContent>
          <Typography>
            {`${t('record.record_time_under')} ${timeDiffLimit} ${t(
              'record.minutes'
            )}. ${t('record.delete_record_confirm')}`}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={() => setTimeDifferenceDeleteSuggest(false)}
            color="primary"
          >
            {t('common.cancel')}
          </Button>

          <Button
            onClick={() =>
              handleRecordDelete(clockOutRecordWithUpdatedAttributes)
            }
            color="danger"
          >
            {t('common.remove')}
          </Button>
        </DialogActions>
      </Dialog>
      {uploadDialogOpen ? (
        <UploadProgressDialog
          filesCount={filesCount}
          filesUploadingCount={filesUploadingCount}
          open={uploadDialogOpen}
          close={() => onCancelAdding()}
          progress={uploadProgress}
          uploadSizes={uploadSizes}
        ></UploadProgressDialog>
      ) : null}
    </>
  );
}

export default ClockOutRecordDialog;
