import { createAsyncThunk } from '@reduxjs/toolkit';
import { setUpdateSaleInvoiceDraft } from 'store/invoices/services/setUpdateSaleInvoiceDraft';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const updateSaleInvoiceDraft = createAsyncThunk(
  'saleInvoices/updateSaleInvoiceDraft',
  async (params, { getState, dispatch }) => {
    console.log('params', params);
    let { saleInvoice } = params;

    const updatedSaleInvoice = { ...saleInvoice };
    if ('hasChanges' in updatedSaleInvoice) {
      delete updatedSaleInvoice.hasChanges;
    }
    saleInvoice = updatedSaleInvoice;

    const saleInvoiceId = saleInvoice.id;

    const selectedProjectId = selectSelectedProjectId(getState());
    if (!saleInvoiceId) {
      throw new Error('No sale invoice id provided');
    }

    console.log('Updating sale invoice draft', saleInvoice);

    const response = await setUpdateSaleInvoiceDraft(
      selectedProjectId,
      saleInvoiceId,
      saleInvoice
    );

    return response;
  }
);
