import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import {
  clearExpensesTrackingData,
  clearExpenseTrackingForDateChange,
} from 'scenes/ExpenseTracking/store/actions/expenses-tracking.actions';
import * as actions from './actions/invoices.actions';
import { createSaleInvoice } from './thunks/createSaleInvoice';
import { createSaleInvoiceDraft } from './thunks/createSaleInvoiceDraft';
import { loadProjectSaleInvoices } from './thunks/loadProjectSaleInvoices';
import { loadProjectSaleInvoiceTables } from './thunks/loadProjectSaleInvoiceTables';
import { updateSaleInvoiceDraft } from './thunks/updateSaleInvoiceDraft';
import {
  addDraftInvoiceRow,
  addDraftInvoiceRows,
  resetSaleInvoiceChanges,
  setActiveSaleInvoiceDraft,
  updateDraftInvoice,
  updateDraftInvoiceRow,
} from './actions/draftInvoice.actions';
import { loadProjectSaleInvoiceDrafts } from './thunks/loadProjectSaleInvoiceDrafts';
import { deleteSaleInvoiceDraft } from './thunks/deleteSaleInvoiceDraft';
import { updateCustomer } from 'store/customer/thunks/updateCustomer.thunk';
import { getCustomerChanges, getPaymentTermChanges } from './utils/helpers';

const saleInvoicesAdapter = createEntityAdapter();
const saleInvoiceDraftsAdapter = createEntityAdapter();
const saleInvoiceTablesAdapter = createEntityAdapter();

const resetState = {
  saleInvoices: saleInvoicesAdapter.getInitialState(),
  saleInvoiceDrafts: saleInvoiceDraftsAdapter.getInitialState(),
  activeSaleInvoiceDraft: null,
  loading: false,
};

export const saleInvoicesSlice = createSlice({
  name: 'saleInvoices',
  initialState: {
    saleInvoices: saleInvoicesAdapter.getInitialState(),
    saleInvoiceDrafts: saleInvoiceDraftsAdapter.getInitialState(),
    activeSaleInvoiceDraft: null,
    loading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder

      // LOAD SALE INVOICES
      .addCase(loadProjectSaleInvoices.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadProjectSaleInvoices.fulfilled, (state, action) => {
        state.loading = false;

        saleInvoicesAdapter.setAll(state.saleInvoices, action.payload);
        // const drafts = action.payload.filter(
        //   (invoice) => invoice.status === 'DRAFT'
        // );
      })
      .addCase(loadProjectSaleInvoices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })

      // LOAD DRAFTS
      .addCase(loadProjectSaleInvoiceDrafts.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadProjectSaleInvoiceDrafts.fulfilled, (state, action) => {
        state.loading = false;
        const sortedDrafts = action.payload.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
        saleInvoiceDraftsAdapter.setAll(state.saleInvoiceDrafts, sortedDrafts);
      })
      .addCase(loadProjectSaleInvoiceDrafts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })

      // LOAD TABLES
      .addCase(loadProjectSaleInvoiceTables.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadProjectSaleInvoiceTables.fulfilled, (state, action) => {
        saleInvoiceTablesAdapter.setMany(
          state.saleInvoiceTables,
          action.payload
        );
        state.loading = false;
      })
      .addCase(loadProjectSaleInvoiceTables.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })

      // CLEAR STATE
      .addCase(clearExpensesTrackingData, (state) => {
        return resetState;
      })
      .addCase(clearExpenseTrackingForDateChange, (state) => {
        state.saleInvoices = saleInvoicesAdapter.getInitialState();
      })

      // SET LOADED AMOUNT
      .addCase(actions.setSaleInvoiceLoadedAmount, (state, action) => {
        state.saleInvoiceLoadedAmount = action.payload;
      })

      // CREATE SALE INVOICE
      .addCase(createSaleInvoice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createSaleInvoice.fulfilled, (state, action) => {
        state.loading = false;
        console.log('action.payload', action.payload);
        saleInvoicesAdapter.upsertOne(state.saleInvoices, action.payload);
        saleInvoiceDraftsAdapter.removeOne(
          state.saleInvoiceDrafts,
          action.payload.id
        );
        state.activeSaleInvoiceDraft = null;
        enqueueSnackbar('Myyntilasku tallennettu', {
          variant: 'success',
        });
      })
      .addCase(createSaleInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        enqueueSnackbar('Virhe luodessa myyntilaskua', {
          variant: 'error',
        });
      })

      // CREATE DRAFT
      .addCase(createSaleInvoiceDraft.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createSaleInvoiceDraft.fulfilled, (state, action) => {
        state.loading = false;
        saleInvoicesAdapter.addOne(state.saleInvoices, action.payload);
        saleInvoiceDraftsAdapter.addOne(
          state.saleInvoiceDrafts,
          action.payload
        );
        state.activeSaleInvoiceDraft = action.payload.id;
        enqueueSnackbar('Myyntilaskuluonnos tallennettu', {
          variant: 'success',
        });
      })
      .addCase(createSaleInvoiceDraft.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        enqueueSnackbar('Virhe luodessa myyntilaskua', {
          variant: 'error',
        });
      })

      // UPDATE DRAFT
      .addCase(updateSaleInvoiceDraft.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateSaleInvoiceDraft.fulfilled, (state, action) => {
        state.loading = false;
        saleInvoicesAdapter.upsertOne(state.saleInvoices, action.payload);
        enqueueSnackbar('Myyntilaskuluonnos tallennettu', {
          variant: 'success',
        });
      })
      .addCase(updateSaleInvoiceDraft.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        enqueueSnackbar('Virhe tallennettaessa luonnosta', {
          variant: 'error',
        });
      })

      // DELETE DRAFT
      .addCase(deleteSaleInvoiceDraft.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteSaleInvoiceDraft.fulfilled, (state, action) => {
        state.loading = false;
        saleInvoiceDraftsAdapter.removeOne(
          state.saleInvoiceDrafts,
          action.meta.arg.saleInvoice.id
        );
        enqueueSnackbar('Myyntilaskuluonnos poistettu', {
          variant: 'success',
        });
      })
      .addCase(deleteSaleInvoiceDraft.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        enqueueSnackbar('Virhe poistaessa luonnosta', {
          variant: 'error',
        });
      })

      // DRAFT ACTIONS
      .addCase(setActiveSaleInvoiceDraft, (state, action) => {
        state.activeSaleInvoiceDraft = action.payload.invoiceId;
      })
      .addCase(resetSaleInvoiceChanges, (state, action) => {
        const draft =
          state.saleInvoiceDrafts.entities[action.payload.invoiceId];
        if (!draft) return;

        saleInvoiceDraftsAdapter.upsertOne(state.saleInvoiceDrafts, {
          ...draft,
          hasChanges: false,
        });
      })

      .addCase(updateDraftInvoice, (state, action) => {
        const { invoiceId, changes } = action.payload;
        saleInvoiceDraftsAdapter.upsertOne(state.saleInvoiceDrafts, {
          id: invoiceId,
          ...state.saleInvoiceDrafts.entities[invoiceId],
          ...changes,
          hasChanges: true,
        });
      })
      .addCase(updateDraftInvoiceRow, (state, action) => {
        const { invoiceId, rowId, changes } = action.payload;
        const invoice = state.saleInvoiceDrafts.entities[invoiceId];
        if (!invoice) return;

        saleInvoiceDraftsAdapter.upsertOne(state.saleInvoiceDrafts, {
          ...invoice,
          rows: invoice.rows.map((row) =>
            row.id === rowId ? { ...row, ...changes } : row
          ),
          hasChanges: true,
        });
      })
      .addCase(addDraftInvoiceRow, (state, action) => {
        const { invoiceId, row } = action.payload;
        const invoice = state.saleInvoiceDrafts.entities[invoiceId];
        if (!invoice) return;

        saleInvoiceDraftsAdapter.upsertOne(state.saleInvoiceDrafts, {
          ...invoice,
          rows: [...invoice.rows, row],
          hasChanges: true,
        });
      })
      .addCase(addDraftInvoiceRows, (state, action) => {
        const { invoiceId, rows } = action.payload;
        const invoice = state.saleInvoiceDrafts.entities[invoiceId];
        if (!invoice) return;

        saleInvoiceDraftsAdapter.upsertOne(state.saleInvoiceDrafts, {
          ...invoice,
          rows: [...invoice.rows, ...rows],
          hasChanges: true,
        });
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        const customer = action.payload;
        const drafts = Object.values(state.saleInvoiceDrafts.entities).filter(
          (draft) => draft.customer === customer.id
        );

        drafts.forEach((draft) => {
          const changes = {
            ...getCustomerChanges(customer),
            ...(customer.paymentTerm
              ? getPaymentTermChanges(customer.paymentTerm, draft.date)
              : {}),
          };

          saleInvoiceDraftsAdapter.upsertOne(state.saleInvoiceDrafts, {
            ...draft,
            ...changes,
            hasChanges: true,
          });

          
        });
      });
  },
});
