import { initSettings } from './initSettings';
import { fetchReportDataFromRecords } from './fetchReportDataFromRecordIds.util';
import { processData } from './processData.util';

const reports = require('./reports');

export const generateReportFromRecords = async (
  records,
  resources,
  assignments,
  users,
  generationParams,
  projects,
  selectedProjectId,
  recordAllowancesGroupedByRecordId
) => {
  const project = projects.find((project) => project.id === selectedProjectId);

  const settings = initSettings(generationParams);

  let reportData = await fetchReportDataFromRecords(
    project,
    records,
    resources,
    projects,
    assignments,
    users,
    recordAllowancesGroupedByRecordId,
    generationParams
  );

  const processedReportData = processData(reportData, settings);

  let tableRows;

  if (generationParams.selectedReportType === 'balanco_salary_report') {
    // run with hour salary report logic
    tableRows = await reports['hour_salary_report'].createReport(
      processedReportData,
      settings,
      generationParams
    );
  } else {
    tableRows = await reports[generationParams.selectedReportType].createReport(
      processedReportData,
      settings,
      generationParams
    );
  }

  let filename;

  if (generationParams.selectedReportType === 'hour_listing_report') {
    const { documentName, documentFilename } = await reports[
      generationParams.selectedReportType
    ].docName(settings);

    filename = documentFilename;
  }

  const resp = {
    documentData: {
      document: tableRows,
      filename,
    },
    processedData: {
      reportType: processedReportData.reportType,
      resources: processedReportData.resources,
      fromDate:
        generationParams.selectedReportType !== 'hour_listing_report'
          ? processedReportData.fromDate
          : undefined,
      toDate:
        generationParams.selectedReportType !== 'hour_listing_report'
          ? processedReportData.toDate
          : undefined,
      dataRows: tableRows.dataRows,
      headerRows: tableRows.headerRows,
      userTotalWorkAmountsByUserId: tableRows.userTotalWorkAmountsByUserId,
    },
  };

  return resp;
};
