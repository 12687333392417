import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { selectAllProjectsOfBusiness, selectSelectedProject } from 'store/projects/selectors/projectsSelectors';


const selectTimelinesState = (state) => state.timelines;

export const selectSelectedTimelineId = createSelector(
  [selectTimelinesState],
  (state) => state.selectedTimelineId
);

export const selectBusinessIdTimelines = createSelector(
  [selectTimelinesState],
  (state) => state.businessIdTimelines.entities
);

export const selectProjectsWithTimelines = createSelector(
  [selectBusinessIdTimelines, selectAllProjectsOfBusiness, selectSelectedProject],
  (timelines, projects, currentProject) => {
    const result = projects.map((project) => {
      const timelinesOfProject = Object.values(timelines).filter(
        (timeline) => timeline.projectId === project.id
      );
      if (timelinesOfProject.length === 0) {
        return null;
      }
      const projectWithTimelines = {
        ...project,
        timelines: timelinesOfProject,
      }
      return projectWithTimelines;
    })

    // Filter current project out of the list
    return result.filter((project) => project !== null);
  }
);

export const selectTasksEntities = createSelector(
  [selectTimelinesState],
  (state) => state.tasks.entities
);

export const selectNewTasksEntities = createSelector(
  [selectTimelinesState],
  (state) => state.newTasks.entities
);

export const selectTaskModifications = createSelector(
  [selectTimelinesState],
  (state) => state.taskModifications
);

export const selectTasksArray = createSelector([selectTasksEntities], (tasks) =>
  Object.values(tasks)
);

export const selectNewTasksArray = createSelector(
  [selectNewTasksEntities],
  (newTasks) => Object.values(newTasks)
);

export const selectAllTimelines = createSelector(
  [selectTimelinesState],
  (state) => state.timelines
);

export const selectIsLoadingTasks = createSelector(
  [selectTimelinesState],
  (state) => state.loadingTasks
);

export const selectIsLoadingTimelines = createSelector(
  [selectTimelinesState],
  (state) => state.loadingTimelines
);

export const selectAllLinkedTasksIds = createSelector(
  [selectTimelinesState],
  (state) => state.linkedTasks
);

export const selectIsAddNewTimelineDialogOpen = createSelector(
  [selectTimelinesState],
  (state) => state.isAddNewTimelineDialogOpen
);

export const selectTimelinesZoomValue = createSelector(
  [selectTimelinesState],
  (state) => state.timelinesZoomValue
);

export const selectSelectedTimeline = createSelector(
  [selectAllTimelines, selectSelectedTimelineId],
  (timelines, selectedTimelineId) => {
    const selectedTimeline = timelines.find(
      (timeline) => timeline.id === selectedTimelineId
    );
    return selectedTimeline;
  }
);

export const selectSelectedTimelineDates = createSelector(
  [selectSelectedTimeline],
  (selectedTimeline) => {
    return {
      startDate: selectedTimeline.startDate,
      endDate: selectedTimeline.endDate,
    };
  }
);

export const selectSelectedTimelineIsLinked = createSelector(
  [selectSelectedTimeline],
  (selectedTimeline) => {
    return selectedTimeline.linked;
  }
);

export const selectSelectedTimelineWeeksDifference = createSelector(
  [selectSelectedTimelineDates, selectTimelinesZoomValue],
  ({ startDate, endDate }, timelinesZoomValue) => {
    if (startDate && endDate) {
      const adjustedStartMoment =
        timelinesZoomValue === 'week'
          ? moment(startDate)
          : moment(startDate).startOf('month');

      const adjustedEndMoment = moment(endDate);

      const weeksBetweenStartAndEnd = adjustedEndMoment.diff(
        adjustedStartMoment,
        'weeks'
      );

      return {
        timelineStartDate: adjustedStartMoment.toISOString(),
        timelineEndDate: adjustedEndMoment.toISOString(),
        weeksBetweenStartAndEnd,
      };
    }
    return {
      timelineStartDate: null,
      timelineEndDate: null,
      weeksBetweenStartAndEnd: null,
    };
  }
);

export const selectDeletedTasks = createSelector(
  [selectTasksArray, selectNewTasksArray, selectTaskModifications],
  (tasks, newTasks, modifications) => {
    const combinedTasks = [...tasks, ...newTasks].map((task) => {
      const modification = modifications[task.id];
      return modification ? { ...task, ...modification } : task;
    });

    const deletedTasks = combinedTasks.filter((task) => task.deletedAt);

    return deletedTasks;
  }
);

export const selectModifiedTasks = createSelector(
  [selectTasksArray, selectTaskModifications],
  (tasks, modifications) => {
    const modifiedTasks = tasks.filter((task) => modifications[task.id]);

    return modifiedTasks.map((task) => {
      const modification = modifications[task.id];
      return { ...task, ...modification };
    });
  }
);

export const selectModifiedExistingTasks = createSelector(
  [selectTasksArray, selectTaskModifications],
  (tasks, modifications) =>
    tasks.map((task) => {
      const modification = modifications[task.id];
      return modification ? { ...task, ...modification } : task;
    })
);

// export const selectAllTasksWithModifications = createSelector(
//   [selectModifiedExistingTasks, selectNewTasksArray, selectTaskModifications],
//   (modifiedExistingTasks, newTasks, modifications) => {
//     const combinedTasks = [...modifiedExistingTasks, ...newTasks]
//       .map((task) => {
//         const modification = modifications[task.id];
//         return modification ? { ...task, ...modification } : task;
//       })
//       .filter((task) => !task.deletedAt);

//     let nextOrder = combinedTasks.length + 1;

//     combinedTasks.forEach((task) => {
//       if (task.order === undefined) {
//         console.log(
//           'task with undefined: assigning next order ',
//           task,
//           nextOrder
//         );
//         task.order = nextOrder.toString();
//         nextOrder++;
//       }
//     });

//     return combinedTasks.sort(
//       (a, b) => parseInt(a.order, 10) - parseInt(b.order, 10)
//     );
//   }
// );

const selectCombinedTasksWithModifications = createSelector(
  [selectModifiedExistingTasks, selectNewTasksArray, selectTaskModifications],
  (selectModifiedExistingTasks, newTasks, modifications) => {
    const modifiedExistingTasks = selectModifiedExistingTasks.map((task) => ({
      ...task,
      ...(modifications[task.id] || {}),
    }));

    return [...modifiedExistingTasks, ...newTasks];
  }
);

const selectTasksBySelectedTimeline = createSelector(
  [selectCombinedTasksWithModifications, selectSelectedTimelineId],
  (tasks, selectedTimelineId) =>
    tasks.filter((task) => {
      return task.timelineId === selectedTimelineId;
    })
);

export const selectNonDeletedTasksBySelectedTimeline = createSelector(
  [selectTasksBySelectedTimeline],
  (tasks) => tasks.filter((task) => !task.deletedAt)
);

const selectSortedTasksBySelectedTimeline = createSelector(
  [selectNonDeletedTasksBySelectedTimeline],
  (tasks) => {
    let nextOrder = tasks.length + 1;

    const tasksWithOrders = tasks.map((task) => {
      if (task.order === undefined || task.order === null) {
        nextOrder += 1;
        return { ...task, order: nextOrder };
      }
      return task;
    });

    return tasksWithOrders.sort(
      (a, b) => parseInt(a.order, 10) - parseInt(b.order, 10)
    );
  }
);

export const selectAllTasksWithModificationsForSelectedTimeline =
  createSelector([selectSortedTasksBySelectedTimeline], (tasks) => tasks);

export const selectAllParentTasksWithChildsInside = createSelector(
  [selectSortedTasksBySelectedTimeline],
  (tasks) => {
    const byId = tasks.reduce((acc, task) => {
      acc[task.id] = { ...task, children: [] };
      return acc;
    }, {});

    const topLevelTasks = tasks.reduce((acc, task) => {
      if (task.parentId && byId[task.parentId]) {
        byId[task.parentId].children.push(byId[task.id]);
      } else {
        acc.push(byId[task.id]);
      }
      return acc;
    }, []);

    return [...topLevelTasks];
  }
);

export const selectAllTasksLinkedTasks = createSelector(
  [selectAllTasksWithModificationsForSelectedTimeline, selectAllLinkedTasksIds],
  (tasks, linkedTasks) =>
    tasks.filter((task) => {
      return linkedTasks.includes(task.id);
    })
);

export const selectMasterParentProjectLogo = createSelector(
  [selectTimelinesState],
  (state) => state.masterParentProjectLogo
);
