import { createSelector } from '@reduxjs/toolkit';

const selectProjectGalleryState = (state) => state.projectGallery;

export const selectIsGalleryLoading = createSelector(
  [selectProjectGalleryState],
  (state) => state.loading
);

export const selectIsUpdatePending = createSelector(
  [selectProjectGalleryState],
  (state) => state.updatePending
);

export const selectIsLoadingFilePermissions = createSelector(
  [selectProjectGalleryState],
  (state) => state.loadingFilePermissions
);

export const selectProjectImages = createSelector(
  [selectProjectGalleryState],
  (state) => state.projectImages
);

export const selectSelectedFilePermissionId = createSelector(
  [selectProjectGalleryState],
  (state) => state.selectedFilePermissionId
);

export const selectProjectImagesArray = createSelector(
  [selectProjectImages],
  (images) => Object.values(images)
);

export const selectMediaFilePermissions = createSelector(
  [selectProjectGalleryState],
  (state) => state.mediaFilePermissions.entities
);

export const selectMediaFilePermissionsArray = createSelector(
  [selectMediaFilePermissions],
  (filePermissions) => Object.values(filePermissions)
);

export const selectGalleryLastEvaluatedKey = createSelector(
  [selectProjectGalleryState],
  (state) => state.lastEvaluatedKey
);

export const selectIsMoreToFetch = createSelector(
  [selectProjectGalleryState],
  (state) => state.isMoreToFetch
);
export const selectUpdatingFilePermissions = createSelector(
  [selectProjectGalleryState],
  (state) => state.updatingFilePermissions
);
