import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { generateBalancoReportXlsx } from 'scenes/WorkDemo/utils/report-generation/generateBalancoReportXlsx.util';
import { selectSelectedEmployer } from 'store/breadcrumbs/selectors/breadcrumbs.selectors';
import {
  selectProjectsArray,
  selectSelectedProject,
} from 'store/projects/selectors/projectsSelectors';
import {
  selectProcessedSalaryRows,
  selectReportBasicData,
} from 'store/reports/selectors/reports.selectors';
import { selectSelectedEmployersBusinessIds } from '../../selectors/workSearchParamsSelectors';

export const createBalancoXlsx = createAsyncThunk(
  'workCalendar/createBalancoXlsx',
  async (_, { getState }) => {
    const salaryRows = selectProcessedSalaryRows(getState());

    const reportBasicData = selectReportBasicData(getState());

    const projects = selectProjectsArray(getState());

    const activeEmployerId = selectSelectedEmployer(getState());

    const workCalendarSelectedBusinessIds = selectSelectedEmployersBusinessIds(
      getState()
    );

    const selectedProject = selectSelectedProject(getState());

    const navigationActiveEmployer = projects.find(
      (project) => project.id === activeEmployerId
    );

    let businessId;

    if (workCalendarSelectedBusinessIds[0]) {
      businessId = workCalendarSelectedBusinessIds[0];
    } else if (navigationActiveEmployer) {
      businessId = navigationActiveEmployer.business_id;
    } else {
      businessId = selectedProject.business_id;
    }

    const foundEmployerFromBusinessId = projects.find(
      (project) =>
        project.business_id === businessId &&
        project.businessTypeEnum === 'EMPLOYER'
    );

    const employerName =
      navigationActiveEmployer?.name || foundEmployerFromBusinessId?.name;

    const { fromDate, toDate } = reportBasicData;

    const earningPeriod = `${dayjs(fromDate).format('DD.MM.YYYY')} - ${dayjs(
      toDate
    ).format('DD.MM.YYYY')}`;

    const balancoReportData = { salaryRows, earningPeriod, employerName };

    generateBalancoReportXlsx(balancoReportData);
  }
);
