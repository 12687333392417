import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import writeXlsxFile from 'write-excel-file';

var isoWeek = require('dayjs/plugin/isoWeek');

dayjs.extend(isoWeek);

const baseHourlySalaryRowColumns = [
  { name: 'Työntekijänumero' },
  { name: 'Työntekijän nimi' },
  { name: 'Muuta huomioitavaa' },
  { name: 'Muutostiedot työntekijän tietoihin' },
  { name: 'Kustannuspaikka' },
  {
    name: 'Tuntityö h',
    salaryCode: '1004',
    salaryType: 'WORK_SALARY_HOURLY',
  },
  { name: 'Lisätyö h', salaryCode: '1201' },
  { name: 'Ylityö h', salaryCode: '1220' },
  { name: 'Ylityö 50%, vrk h', salaryCode: '1230' },
  { name: 'Ylityö 50%, vko h', salaryCode: '1232' },
  { name: 'Ylityö 100%, vrk  h', salaryCode: '1231' },
  { name: 'Ylityö 100%, vko h', salaryCode: '1233' },
  { name: 'Päivystys/ varallaolo h', salaryCode: '2520' },
  { name: 'Iltalisä h', salaryCode: '1447' },
  { name: 'Yölisä h', salaryCode: '1448' },
  { name: 'Lauantailisä h', salaryCode: '1457' },
  { name: 'Sunnuntaityö h', salaryCode: '1410' },
  { name: 'Arkipyhäkorvaus h', salaryCode: '2340' },
  {
    name: 'Sairaustunnit  h',
    salaryCode: '2410',
    salaryType: 'SICK_LEAVE_PAY',
  },
  {
    name: 'Lomapäivät kpl',
    salaryCode: '2201',
    salaryType: 'HOLIDAY_PAY',
  },
  { name: 'Pekkanen h', salaryCode: '1462' },
  { name: 'Urakkapalkka €', salaryCode: '1020-2' },
  { name: 'Urakkatunnit h', salaryCode: '1020' },
  { name: 'Tuotantolisä €', salaryCode: '1510' },
  { name: 'Bonus €', salaryCode: '2130-2' },
  {
    name: 'Km-korvaus kpl',
    salaryCode: '4050',
    salaryType: 'KILOMETER_ALLOWANCE',
  },
  {
    name: 'Kokopäiväraha kpl',
    salaryCode: '4010',
    salaryType: 'FULL_DAILY_ALLOWANCE',
  },
  {
    name: 'Puolipäiväraha kpl',
    salaryCode: '4020',
    salaryType: 'DAILY_ALLOWANCE_HALF_DAY',
  },
  {
    name: 'Ateriakorvaus kpl',
    salaryCode: '4030',
    salaryType: 'MEAL_ALLOWANCE',
  },
];

const baseMonthlySalaryRowColumns = [
  { name: 'Työntekijänumero' },
  { name: 'Työntekijän nimi' },
  { name: 'Muuta huomioitavaa' },
  { name: 'Muutostiedot työntekijän tietoihin' },
  { name: 'Kustannuspaikka' },
  { name: 'Työpäivät kpl', salaryCode: '1003' },
  {
    name: 'Sairauspäivät kpl',
    salaryCode: '2411',
    salaryType: 'SICK_LEAVE_PAY',
    // salaryType: 'SICK_LEAVE_WAGE_MONTH',
  },
  { name: 'Lomapäivät kpl', salaryCode: '2204', salaryType: 'HOLIDAY_PAY' },
  { name: 'Bonus €', salaryCode: '2130-2' },
  {
    name: 'Km-korvaus kpl',
    salaryCode: '4050',
    salaryType: 'KILOMETER_ALLOWANCE',
  },
  {
    name: 'Kokopäiväraha kpl',
    salaryCode: '4010',
    salaryType: 'FULL_DAILY_ALLOWANCE',
  },
  {
    name: 'Puolipäiväraha',
    salaryCode: '4020',
    salaryType: 'DAILY_ALLOWANCE_HALF_DAY',
  },
];

export const generateBalancoReportXlsx = async (balancoReportData) => {
  const { salaryRows, earningPeriod, employerName } = balancoReportData;

  console.log('SALARY ROWS IN XLSX GENERATION', salaryRows);

  let hourSalaryRows = [];
  let monthSalaryRows = [];
  let additionalSalaries = [];

  salaryRows.forEach((salaryRow) => {
    const isMonthlySalaryRow = salaryRow.salaryType === 'WORK_SALARY_MONTHLY';
    const isHourlySalaryRow = salaryRow.salaryType === 'WORK_SALARY_HOURLY';

    if (isHourlySalaryRow) {
      hourSalaryRows.push(salaryRow);
    } else if (isMonthlySalaryRow) {
      monthSalaryRows.push(salaryRow);
    } else {
      additionalSalaries.push(salaryRow);
    }
  });

  const payday = dayjs().isoWeekday(5).format('DD.MM.YYYY'); // next friday

  const returnSheetBaseInfoHeader = (salaryType) => {
    return [
      [
        {
          value: employerName,
          fontWeight: 'bold',
          fontSize: 26,
        },
      ],

      [{ value: '', height: 20 }],
      [
        {
          value: salaryType === 'HOURLY' ? 'Tuntipalkat' : 'Kuukausipalkat',
          fontSize: 18,
          borderColor: '#dc23be',
          height: 22,
        },
      ],
      [
        {
          value: 'Palkkakausi',
          fontSize: 16,
          borderColor: '#dc23be',
          height: 22,
        },
        {
          value: earningPeriod,
          fontSize: 16,
          borderColor: '#dc23be',
          align: 'center',
        },
      ],
      [
        {
          value: 'Palkkapäivä',
          fontSize: 16,
          borderColor: '#dc23be',
          height: 22,
        },
        {
          value: payday, // next Friday
          fontSize: 16,
          borderColor: '#dc23be',
          align: 'center',
        },
      ],

      [{ value: '', height: 20 }],
    ];
  };

  const hourSalaryTableData = returnSheetBaseInfoHeader('HOURLY');

  const monthSalaryTableData = returnSheetBaseInfoHeader('MONTHLY');

  const hourlySalaryHeaderRow = [];

  const hourlySalaryCodesRow = [];

  const monthlySalaryHeaderRow = [];

  const monthlySalaryCodesRow = [];

  baseHourlySalaryRowColumns.forEach((column) => {
    hourlySalaryHeaderRow.push({
      value: column.name,
      fontSize: 18,
      align: 'center',
      alignVertical: 'center',
      backgroundColor: '#dc23be',
      color: '#FFFFFF',
      borderColor: '#000000',
      height: 35,
      wrap: true,
    });

    hourlySalaryCodesRow.push({
      value: column.salaryCode ? column.salaryCode : '',
      backgroundColor: '#dc23be',
      color: '#FFFFFF',
      align: 'center',
      alignVertical: 'center',
      fontSize: 16,
      leftBorderColor: '#FFFFFF',
      rightBorderColor: '#FFFFFF',
      bottomBorderColor: '#000000',
      height: 22,
    });
  });

  baseMonthlySalaryRowColumns.forEach((column) => {
    monthlySalaryHeaderRow.push({
      value: column.name,
      fontSize: 18,
      align: 'center',
      alignVertical: 'center',
      backgroundColor: '#dc23be',
      color: '#FFFFFF',
      borderColor: '#000000',
      height: 35,
      wrap: true,
    });

    monthlySalaryCodesRow.push({
      value: column.salaryCode ? column.salaryCode : '',
      backgroundColor: '#dc23be',
      color: '#FFFFFF',
      align: 'center',
      alignVertical: 'center',
      fontSize: 16,
      leftBorderColor: '#FFFFFF',
      rightBorderColor: '#FFFFFF',
      bottomBorderColor: '#000000',
      height: 22,
    });
  });

  hourSalaryTableData.push(hourlySalaryHeaderRow);

  hourSalaryTableData.push(hourlySalaryCodesRow);

  monthSalaryTableData.push(monthlySalaryHeaderRow);

  monthSalaryTableData.push(monthlySalaryCodesRow);

  hourSalaryRows.forEach((row, index) => {
    const rowBackgroundColor = index % 2 === 0 ? '#edebeb' : '#dedcdc';

    const userSalaryRow = [
      {
        value: row.personNumber ? row.personNumber : 'Ei henkilönumeroa',
        height: 16,
        backgroundColor: rowBackgroundColor,
        borderColor: '#FFFFFF',
      }, // TYÖNTEKIJÄNUMERO
      {
        value: String(row.personName),
        type: String,
        backgroundColor: rowBackgroundColor,
        borderColor: '#FFFFFF',
      }, // TYÖNTEKIJÄN NIMI
      {
        value: String(row.rowComment ? row.rowComment : ''),
        type: String,
        backgroundColor: rowBackgroundColor,
        borderColor: '#FFFFFF',
      }, // MUUTA HUOMIOITAVAA
      {
        value: String(row.changeInformation ? row.changeInformation : ''),
        type: String,
        backgroundColor: rowBackgroundColor,
        borderColor: '#FFFFFF',
      }, // MUUTOSTIEDOT
      {
        value: row.projectName ? row.projectName : '',
        backgroundColor: rowBackgroundColor, // KUSTANNUSPAIKKA
        borderColor: '#FFFFFF',
      },
      {
        value: Number(row.unitCount),
        align: 'center',
        type: Number,
        backgroundColor: rowBackgroundColor,
        borderColor: '#FFFFFF',
      },
    ];
    baseHourlySalaryRowColumns
      .filter((column) => column.salaryCode && column.salaryCode !== '1004')
      .forEach((additionalSalaryColumn) => {
        const foundAdditionalSalaryForUserAndProject = additionalSalaries.find(
          (additionalSalaryRow) => {
            const isSameUserAsMainSalaryRow =
              additionalSalaryRow.userId === row.userId;

            const isSameSalaryTypeAsColumn =
              additionalSalaryRow.salaryType ===
              additionalSalaryColumn.salaryType;

            const isSameProjectAsMainSalaryRow =
              additionalSalaryRow.projectId === row.projectId;

            return (
              isSameSalaryTypeAsColumn &&
              isSameUserAsMainSalaryRow &&
              isSameProjectAsMainSalaryRow
            );
          }
        );

        if (foundAdditionalSalaryForUserAndProject) {
          userSalaryRow.push({
            value: Number(foundAdditionalSalaryForUserAndProject.unitCount),
            align: 'center',
            backgroundColor: rowBackgroundColor,
            borderColor: '#FFFFFF',
          });
        } else {
          userSalaryRow.push({
            value: '',
            backgroundColor: rowBackgroundColor,
            borderColor: '#FFFFFF',
          });
        }
      });

    hourSalaryTableData.push(userSalaryRow);
  });

  monthSalaryRows.forEach((row, index) => {
    const rowBackgroundColor = index % 2 === 0 ? '#edebeb' : '#dedcdc';

    const userSalaryRow = [
      {
        value: row.personNumber ? row.personNumber : 'Ei henkilönumeroa',
        height: 16,
        backgroundColor: rowBackgroundColor,
        borderColor: '#FFFFFF',
      }, // TYÖNTEKIJÄNUMERO
      {
        value: String(row.personName),
        type: String,
        backgroundColor: rowBackgroundColor,
        borderColor: '#FFFFFF',
      }, // TYÖNTEKIJÄN NIMI
      {
        value: String(row.rowComment ? row.rowComment : ''),
        type: String,
        backgroundColor: rowBackgroundColor,
        borderColor: '#FFFFFF',
      }, // MUUTA HUOMIOITAVAA
      {
        value: String(row.changeInformation ? row.changeInformation : ''),
        type: String,
        backgroundColor: rowBackgroundColor,
        borderColor: '#FFFFFF',
      }, // MUUTOSTIEDOT
      {
        value: row.projectDimension ? row.projectDimension : '',
        backgroundColor: rowBackgroundColor,
        borderColor: '#FFFFFF',
        // KUSTANNUSPAIKKA
      },
      {
        value: Number(row.totalWorkDays),
        align: 'center',
        backgroundColor: rowBackgroundColor,
        borderColor: '#FFFFFF',
      },
    ];
    monthSalaryTableData.push(userSalaryRow);

    baseMonthlySalaryRowColumns
      .filter((column) => column.salaryCode && column.salaryCode !== '1003')
      .forEach((additionalSalaryColumn, index) => {
        const foundAdditionalSalaryForUser = additionalSalaries.find(
          (additionalSalaryRow) =>
            additionalSalaryRow.salaryType ===
              additionalSalaryColumn.salaryType &&
            additionalSalaryRow.userId === row.userId
        );

        if (foundAdditionalSalaryForUser) {
          userSalaryRow.push({
            value: Number(foundAdditionalSalaryForUser.unitCount),
            align: 'center',
            backgroundColor: rowBackgroundColor,
            borderColor: '#FFFFFF',
          });
        } else {
          userSalaryRow.push({
            value: '',
            backgroundColor: rowBackgroundColor,
            borderColor: '#FFFFFF',
          });
        }
      });
  });

  const hourSheetColumns = [
    { width: 30 },
    { width: 30 },
    { width: 40 },
    { width: 50 },
    { width: 30 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 22 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
  ];

  const monthSheetColumns = [
    { width: 30 },
    { width: 30 },
    { width: 40 },
    { width: 50 },
    { width: 30 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
  ];

  try {
    await writeXlsxFile([hourSalaryTableData, monthSalaryTableData], {
      columns: [hourSheetColumns, monthSheetColumns],
      sheets: ['Tuntipalkat', 'Kuukausipalkat'],
      fileName: `balanco_palkka_aineisto`,
    });
  } catch (e) {
    console.log('error', e);
    enqueueSnackbar('Tiedoston kirjoitusvirhe', { variant: 'error' });
  }
};
