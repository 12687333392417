import { createSelector } from '@reduxjs/toolkit';

const selectProjectsState = (state) => state.projects;

export const selectShortTermProjectsSearchFilters = createSelector(
  [selectProjectsState],
  (state) => state.shortTermProjectsSearchFilters
);

export const selectIsLoadingShortTermProjects = createSelector(
  [selectProjectsState],
  (state) => state.isLoadingShortTermProjects
);
