import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import { Translation } from 'react-i18next';

import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import Dialog from 'components/DialogWrapper/DialogWrapper';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';

import TimerIcon from 'components/LightIcons/TimerLight';

//import ClockOutDialog from 'components/ClockOutDialog/ClockOutDialog';
import ProjectItem from './components/ProjectItem';
import RecordItem from './components/RecordItem';
import SideBadge from 'components/SideBadge/SideBadge';
import TimerContent from './components/TimerContent';
import { RECORD_STATUS, RECORD_TYPE } from 'LixaniAPI/enums';
//import PictureMustProfile from 'scenes/Project/components/PictureMustProfile';
import { connect } from 'react-redux';
import { clockInRecord } from 'store/records/thunks/clockInRecord.thunk';
import { openEditRecordDialog } from 'store/records/thunks/openEditRecordDialog.thunk';
import { openClockOutRecordDialog } from 'store/records/thunks/openClockOutRecordDialog';
import Icon from '@mdi/react';
import { mdiTimer, mdiTimerOutline } from '@mdi/js';

const mapStateToProps = (state) => ({
  user: state.root.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    openRecordDialog: (record) => dispatch(openEditRecordDialog(record.id)),
    openClockOutDialog: (projectId) =>
      dispatch(openClockOutRecordDialog(projectId)),
    clockInRecord: ({ projectId, allowClockInWithOutLocation }) =>
      dispatch(clockInRecord({ projectId, allowClockInWithOutLocation })),
  };
};

const styles = (theme) => ({
  root: {
    display: 'inherit',
    // '&:hover': {
    //   color: 'hsla(195,75%,25%,1)',
    //   [theme.breakpoints.up('sm')]: {
    //     color: 'hsla(195,75%,25%,0.75)',
    //   },
    // },
  },
  rootOpen: {
    color: 'hsla(195,75%,25%,1)',
    [theme.breakpoints.up('sm')]: {
      color: 'hsla(195,75%,25%,0.75)',
    },
  },
  iconButton: {
    height: 40,
    padding: 0,
    width: 40,
    [theme.breakpoints.up('sm')]: {
      margin: '0 4px',
    },
  },
  listTitle: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    padding: '8px',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
));
Transition.displayName = 'Transition';

class TimerButton extends Component {
  state = {
    open: false,
    clockOutDialog: false,
    recordDialogOpen: false,
    record: {},
    project: {},
    loading: false,
    profilePictureRequired: false,
    clockInTargetProject: null,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClickClockOut = (record) => {
    this.props.openClockOutDialog(record.project.id);
    this.setState({ record: record });
  };

  closeRecordDialog = () => {
    this.setState({ record: {}, recordDialogOpen: false });
  };

  handleOnClockInFromPictureMust = () => { };

  handleOnClockIn = (project, updatedResource) => {
    this.props.clockInRecord({
      projectId: project.id,
      allowClockInWithOutLocation: false,
    });
  };

  timerButton = null;

  render() {
    const { classes, inRecords, inRecordsLoading, lastProjects } = this.props;
    const { open } = this.state;
    const records = inRecords.filter((x) => x.status === RECORD_STATUS.IN);

    const timerContent = (
      <Translation ns={['common']}>
        {(t) => (
          <TimerContent onClose={this.handleClose}>
            {inRecords.map((record) => (
              <RecordItem
                key={record.id}
                record={record}
                onClickClockOut={() => this.handleClickClockOut(record)}
                onClickRecordDialog={() => this.props.openRecordDialog(record)}
                onClose={() => this.handleClose()}
              />
            ))}
            {lastProjects && lastProjects.length > 0 && (
              <div className={classes.listTitle}>
                {t('common.latest_projects')}
              </div>
            )}
            {lastProjects.map((item) => (
              <ProjectItem
                project={item}
                key={item.id}
                onClickClockIn={() => this.handleOnClockIn(item)}
                loading={this.state.loading}
                onCloseParent={this.handleClose}
              />
            ))}
          </TimerContent>
        )}
      </Translation>
    );

    const badge = inRecordsLoading ? (
      <SideBadge badgeContent={<CircularProgress color="inherit" size={12} />}>
        {/* <TimerIcon /> */}
        <Icon path={mdiTimerOutline} size={1} />
      </SideBadge>
    ) : (
      <SideBadge
        badgeContent={records.length}
        color="secondary"
        invisible={records.length === 0}
      >
        <Icon path={mdiTimerOutline} size={1} />
      </SideBadge>
    );

    return (
      <div className={clsx(classes.root, open && classes.rootOpen)}>
        <Hidden xsDown>
          <IconButton
            className={classes.iconButton}
            color="inherit"
            ref={(node) => {
              this.timerButton = node;
            }}
            onClick={this.handleOpen}
            title="Notifications"
          >
            {badge}
          </IconButton>
          <Drawer
            anchor="right"
            classes={{ paper: classes.drawerPaper }}
            onClose={this.handleClose}
            open={open}
          >
            {timerContent}
          </Drawer>
        </Hidden>
        <Hidden smUp>
          <IconButton
            className={classes.iconButton}
            color="inherit"
            onClick={this.handleOpen}
            title="Notifications"
          >
            {badge}
          </IconButton>
          <Dialog
            fullScreen
            open={this.state.open}
            onClose={this.handleClose}
            TransitionComponent={Transition}
          >
            {timerContent}
          </Dialog>
        </Hidden>
        {/* {this.state.clockOutDialog && (
          <ClockOutDialog
            onClose={this.handleCloseClockOutDialog}
            open={this.state.clockOutDialog}
            record={this.state.record}
          />
        )} */}

        {/* {this.props.user && this.state.profilePictureRequired && (
          <PictureMustProfile
            open={this.state.profilePictureRequired}
            onClose={() => this.handleClosePictureMustDialog()}
            resource={this.props.user.resource}
            handleClockIn={this.handleOnClockIn}
            project={this.state.clockInTargetProject}
          />
        )} */}
      </div>
    );
  }
}

TimerButton.defaultProps = {
  classes: {},
  data: {},
  inRecords: [],
};

TimerButton.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  inRecords: PropTypes.array.isRequired,
  inRecordsLoading: PropTypes.bool.isRequired,
  lastProjects: PropTypes.array.isRequired,
  clockInRecord: PropTypes.func.isRequired,
  openRecordDialog: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const TimerButtonCompose = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimerButton);
export default withStyles(styles, { withTheme: true })(TimerButtonCompose);
