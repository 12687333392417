import { createAction } from '@reduxjs/toolkit';

export const openAddNewTimelineDialog = createAction(
  'timelines/openAddNewTimelineDialog'
);

export const closeAddNewTimelineDialog = createAction(
  'timelines/closeAddNewTimelineDialog'
);

export const setTimelinesZoomValue = createAction(
  'timelines/setTimelinesZoomValue'
);

export const clearBusinessIdTimelines = createAction(
  'timelines/clearBusinessIdTimelines'
);
