import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { deleteAdminStatusOfUser } from 'scenes/Settings/store/thunks/deleteAdminStatusOfUser.thunk.js';
import {
  closePermissionsView,
  closeProjectRoles,
  closeProjectUsers,
  dismissMultipleUsersToAssignRole,
  dismissSelectedUsersToAssignRole,
  modifyMultipleUserRoles,
  modifyProjectRole,
  modifySingleUserPermission,
  modifyUserRole,
  searchPermissionsViewUsers,
  selectMultipleUsersToAssignRole,
  selectUserToAssignRole,
  setUsersGroupedBy,
} from '../actions/permissions-view.actions.js';
import { putAdminStatusOfUsers } from '../thunks/admins/putAdminStatusOfUsers.thunk.js';
import { removeAdminStatusOfUsers } from '../thunks/admins/removeAdminStatusOfUsers.thunk.js';
import { loadPermissionsOfProject } from '../thunks/loadPermissionsOfProject';
import { removeRoleFromUserThunk } from '../thunks/roles/removeRoleFromUser.thunk.js';
import { setRoleOfUserThunk } from '../thunks/roles/setRoleOfUser.thunk.js';
import { updatePermissionsOfUsers } from '../thunks/updatePermissionsOfUsers';

import i18n from 'i18next';
import { enqueueSnackbar } from 'notistack';
import { onSearchBusinessClick } from 'scenes/Settings/store/thunks/onSearchBusinessClick.thunk.js';
import { putAdminStatusOfUser } from '../../../Settings/store/thunks/putAdminStatusOfUser.thunk.js';
import { closeEditSingleUserPermissionsDialog } from '../actions/permissons-dialog.actions.js';
import { assignAdminPermissionsForUser } from '../thunks/admins/assignAdminPermissionsForUser.js';
import { loadAdminsOfBusiness } from '../thunks/admins/loadAdminsOfBusiness.js';
import { removeAdminStatusOfUser } from '../thunks/admins/removeAdminStatusOfUser.thunk.js';
import { assignDefaultEmployeeRole } from '../thunks/roles/assignDefaultEmployeeRole.thunk.js';
import { assignDefaultExternalRole } from '../thunks/roles/assignDefaultExternalRole.thunk.js';
import { createProjectRoleThunk } from '../thunks/roles/createProjectRole.thunk.js';
import { deleteProjectRole } from '../thunks/roles/deleteProjectRole.thunk.js';
import { loadProjectRoleChangeHistory } from '../thunks/roles/loadProjectRoleChangeHistory.thunk.js';
import { saveProjectRoleModifications } from '../thunks/roles/saveProjectRoleModifications.thunk.js';
import { saveUserRoleModifications } from '../thunks/roles/saveUserRoleModifications.thunk.js';
import { closeProjectResourcesView } from 'scenes/ProjectResources/store/actions/project-resources.actions.js';

const userPermissionsAdapter = createEntityAdapter({
  selectId: (userPermissions) =>
    `${userPermissions.userId}#${userPermissions.projectId}`,
});
const rolesAdapter = createEntityAdapter({ selectId: (role) => role.roleId });

const adminsAdapter = createEntityAdapter({
  selectId: (userIdAndBusinessId) =>
    `${userIdAndBusinessId.userId}#${userIdAndBusinessId.businessId}`,
});

const assignedDefaultRolesAdapter = createEntityAdapter({
  selectId: (defaultRole) => `${defaultRole.PK}#${defaultRole.SK}`,
});

const changeHistoryItemsAdapter = createEntityAdapter({
  selectId: (changeHistoryItem) => changeHistoryItem.createdAtUuid,
});

export const permissionSlice = createSlice({
  name: 'permissions',
  initialState: {
    userPermissions: userPermissionsAdapter.getInitialState(),
    roles: rolesAdapter.getInitialState(),
    admins: adminsAdapter.getInitialState(),
    assignedDefaultRoles: assignedDefaultRolesAdapter.getInitialState(),
    changeHistoryItems: changeHistoryItemsAdapter.getInitialState(),
    adminsLoaded: false,
    selectedUsersToAssignRole: [],
    userSearchFilter: '',
    isCreatingNewRole: false,
    projectRolePermissionModifications: {},
    assignedDefaultRoleModifications: {},
    userRoleModifications: {},
    userPermissionModifications: {},
    usersGroupedBy: 'business',
    isLoadingChangeHistory: false,
    isDeletingProjectRole: false,
    isUpdatingPermissionsOfUser: false,
    isUpdatingUserRoles: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadPermissionsOfProject.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(loadPermissionsOfProject.fulfilled, (state, action) => {
        action.payload.map((rolesAndPermissionsByProject) => {
          userPermissionsAdapter.setMany(
            state.userPermissions,
            rolesAndPermissionsByProject.permissionExceptions
          );
          rolesAdapter.setMany(state.roles, rolesAndPermissionsByProject.roles);
          assignedDefaultRolesAdapter.setMany(
            state.assignedDefaultRoles,
            rolesAndPermissionsByProject.assignedDefaultRoles
          );
        });
        state.loading = false;
      })
      .addCase(loadPermissionsOfProject.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
      }))
      .addCase(updatePermissionsOfUsers.pending, (state, action) => {
        state.isUpdatingPermissionsOfUser = true;
      })
      .addCase(updatePermissionsOfUsers.fulfilled, (state, action) => {
        state.isUpdatingPermissionsOfUser = false;
        userPermissionsAdapter.setMany(state.userPermissions, action.payload);
        state.userPermissionModifications = {};
        enqueueSnackbar(
          i18n.t('notifications.success.permissions_updated_success'),
          {
            variant: 'success',
          }
        );
      })
      .addCase(updatePermissionsOfUsers.rejected, (state, action) => {
        state.isUpdatingPermissionsOfUser = false;
        enqueueSnackbar(
          i18n.t('notifications.errors.permissions_updated_rejected'),
          {
            variant: 'error',
          }
        );
      })
      .addCase(createProjectRoleThunk.pending, (state) => {
        state.isCreatingNewRole = true;
      })
      .addCase(createProjectRoleThunk.fulfilled, (state, action) => {
        state.isCreatingNewRole = false;
        rolesAdapter.setOne(state.roles, action.payload);
        enqueueSnackbar('Rooli lisätty onnistuneesti', {
          variant: 'success',
        });
      })
      .addCase(createProjectRoleThunk.rejected, (state, action) => {
        state.isCreatingNewRole = false;
      })

      .addCase(setRoleOfUserThunk.fulfilled, (state, action) => {
        userPermissionsAdapter.upsertOne(state.userPermissions, action.payload);
      })

      .addCase(removeRoleFromUserThunk.fulfilled, (state, action) => {
        userPermissionsAdapter.upsertOne(state.userPermissions, {
          ...action.payload,
          roleId: '',
        });
      })

      .addCase(selectUserToAssignRole, (state, action) => {
        if (state.selectedUsersToAssignRole.includes(action.payload)) {
          state.selectedUsersToAssignRole =
            state.selectedUsersToAssignRole.filter(
              (userId) => userId !== action.payload
            );
        } else {
          state.selectedUsersToAssignRole.push(action.payload);
        }
      })
      .addCase(selectMultipleUsersToAssignRole, (state, action) => {
        const userIds = action.payload;

        userIds.forEach((userId) => {
          if (!state.selectedUsersToAssignRole.includes(userId)) {
            state.selectedUsersToAssignRole.push(userId);
          }
        });
      })
      .addCase(dismissMultipleUsersToAssignRole, (state, action) => {
        const userIdsToDismiss = action.payload;

        state.selectedUsersToAssignRole =
          state.selectedUsersToAssignRole.filter(
            (userId) => !userIdsToDismiss.includes(userId)
          );
      })
      .addCase(closePermissionsView, (state) => {
        state.selectedUsersToAssignRole = [];
        state.projectRolePermissionModifications = {};
        state.assignedDefaultRoleModifications = {};
        state.userRoleModifications = {};
        state.userPermissionModifications = {};
        state.userSearchFilter = '';
        state.selectedUsersToAssignRole = [];
      })
      .addCase(searchPermissionsViewUsers, (state, action) => {
        state.userSearchFilter = action.payload;
      })
      .addCase(putAdminStatusOfUsers.fulfilled, (state, action) => {
        adminsAdapter.setMany(state.admins, action.payload);
      })
      .addCase(assignAdminPermissionsForUser.fulfilled, (state, action) => {
        adminsAdapter.setOne(state.admins, action.payload);
        enqueueSnackbar('Admin oikeudet myönnetty henkilölle onnistuneesti', {
          variant: 'success',
        });
      })
      .addCase(removeAdminStatusOfUser.fulfilled, (state, action) => {
        adminsAdapter.removeOne(
          state.admins,
          `${action.payload.userId}#${action.payload.businessId}`
        );
        enqueueSnackbar('Admin oikeudet poistettu henkilöltä onnistuneesti', {
          variant: 'success',
        });
      })
      .addCase(removeAdminStatusOfUsers.fulfilled, (state, action) => {
        adminsAdapter.removeMany(
          state.admins,
          action.payload.map((obj) => `${obj.userId}#${obj.businessId}`)
        );
      })
      .addCase(deleteAdminStatusOfUser.fulfilled, (state, action) => {
        adminsAdapter.removeOne(
          state.admins,
          `${action.payload.userId}#${action.payload.businessId}`
        );
      })
      .addCase(loadAdminsOfBusiness.fulfilled, (state, action) => {
        state.adminsLoaded = true;
        adminsAdapter.setMany(state.admins, action.payload);
      })
      .addCase(onSearchBusinessClick.fulfilled, (state, action) => {
        adminsAdapter.setMany(state.admins, action.payload.admins);
      })

      .addCase(putAdminStatusOfUser.fulfilled, (state, action) => {
        adminsAdapter.setOne(state.admins, action.payload);
      })
      .addCase(deleteProjectRole.pending, (state, action) => {
        state.isDeletingProjectRole = true;
      })
      .addCase(deleteProjectRole.fulfilled, (state, action) => {
        rolesAdapter.setOne(state.roles, action.payload);
        state.isDeletingProjectRole = false;
        enqueueSnackbar('Rooli poistettu onnistuneesti', {
          variant: 'success',
        });
      })
      .addCase(deleteProjectRole.rejected, (state, action) => {
        state.isDeletingProjectRole = false;
      })
      .addCase(modifyProjectRole, (state, action) => {
        const { roleId } = action.payload;

        state.projectRolePermissionModifications[roleId] = {
          ...state.projectRolePermissionModifications[roleId],
          ...action.payload,
        };
      })
      .addCase(saveProjectRoleModifications.fulfilled, (state, action) => {
        const { updatedRoles, updatedAssignedDefaultRoles } = action.payload;

        rolesAdapter.setMany(state.roles, updatedRoles);
        assignedDefaultRolesAdapter.setMany(
          state.assignedDefaultRoles,
          updatedAssignedDefaultRoles
        );
        state.projectRolePermissionModifications = {};
        state.assignedDefaultRoleModifications = {};
        enqueueSnackbar('Muutokset tallennettu onnistuneesti', {
          variant: 'success',
        });
      })
      .addCase(closeProjectRoles, (state, action) => {
        state.projectRolePermissionModifications = {};
        state.assignedDefaultRoleModifications = {};
      })
      .addCase(closeProjectUsers, (state, action) => {
        state.userRoleModifications = {};
        state.userSearchFilter = '';
        state.userPermissionModifications = {};
        state.selectedUsersToAssignRole = [];
      })
      .addCase(assignDefaultEmployeeRole.fulfilled, (state, action) => {
        const { roleId, projectId } = action.payload;
        const newAssignedDefaultRole = {
          PK: `PROJECT#${projectId}`,
          SK: `DEFAULT_ROLE_EMPLOYEE`,
          roleId,
          projectId,
        };

        state.assignedDefaultRoleModifications[
          `${newAssignedDefaultRole.PK}#${newAssignedDefaultRole.SK}`
        ] = newAssignedDefaultRole;
      })
      .addCase(assignDefaultExternalRole.fulfilled, (state, action) => {
        const { roleId, projectId } = action.payload;
        const newAssignedDefaultRole = {
          PK: `PROJECT#${projectId}`,
          SK: `DEFAULT_ROLE_EXTERNAL`,
          roleId,
          projectId,
        };

        state.assignedDefaultRoleModifications[
          `${newAssignedDefaultRole.PK}#${newAssignedDefaultRole.SK}`
        ] = newAssignedDefaultRole;
      })
      .addCase(loadProjectRoleChangeHistory.pending, (state, action) => {
        state.isLoadingChangeHistory = true;
      })
      .addCase(loadProjectRoleChangeHistory.fulfilled, (state, action) => {
        changeHistoryItemsAdapter.setMany(
          state.changeHistoryItems,
          action.payload
        );
        state.isLoadingChangeHistory = false;
      })
      .addCase(loadProjectRoleChangeHistory.rejected, (state, action) => {
        state.isLoadingChangeHistory = false;
      })
      .addCase(modifyUserRole, (state, action) => {
        const { userId, projectId } = action.payload;

        state.userRoleModifications[`${userId}#${projectId}`] = action.payload;
      })
      .addCase(modifyMultipleUserRoles, (state, action) => {
        const userRoleModifications = action.payload;

        userRoleModifications.forEach((userRoleModification) => {
          const { userId, projectId } = userRoleModification;
          state.userRoleModifications[`${userId}#${projectId}`] =
            userRoleModification;
        });
        state.selectedUsersToAssignRole = [];
      })
      .addCase(modifySingleUserPermission, (state, action) => {
        const { userId } = action.payload;

        state.userPermissionModifications[userId] = {
          ...state.userPermissionModifications[userId],
          ...action.payload,
        };
      })
      .addCase(closeEditSingleUserPermissionsDialog, (state, action) => {
        state.userPermissionModifications = {};
      })
      .addCase(saveUserRoleModifications.pending, (state, action) => {
        state.isUpdatingUserRoles = true;
      })
      .addCase(saveUserRoleModifications.fulfilled, (state, action) => {
        const { updatedUserPermissions } = action.payload;
        userPermissionsAdapter.setMany(
          state.userPermissions,
          updatedUserPermissions
        );

        state.userRoleModifications = {};
        enqueueSnackbar(
          i18n.t('notifications.success.permissions_updated_success'),
          {
            variant: 'success',
          }
        );
        state.isUpdatingUserRoles = false;
      })
      .addCase(saveUserRoleModifications.rejected, (state, action) => {
        enqueueSnackbar(
          i18n.t('notifications.errors.permissions_updated_rejected'),
          {
            variant: 'error',
          }
        );
        state.isUpdatingUserRoles = false;
      })
      .addCase(setUsersGroupedBy, (state, action) => {
        state.usersGroupedBy = action.payload;
      })
      .addCase(dismissSelectedUsersToAssignRole, (state, action) => {
        state.selectedUsersToAssignRole = [];
      })
      .addCase(closeProjectResourcesView, (state, action) => {
        state.userSearchFilter = '';
      });
  },
});

export const {
  selectAll: selectAllUsersPermissions,
  selectEntities: selectUsersPermissionsEntities,
  selectById: selectUsersPermissionsById,
  selectIds: selectUsersPermissionsIds,
} = userPermissionsAdapter.getSelectors(
  (state) => state.permissions.userPermissions
);

export const {
  selectEntities: selectRoleEntities,
  selectById: selectRoleById,
  selectIds: selectRoleIds,
} = rolesAdapter.getSelectors((state) => state.permissions.roles);
