import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCreateSaleInvoiceDraft } from 'store/invoices/services/setCreateSaleInvoiceDraft';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const createSaleInvoiceDraft = createAsyncThunk(
  'saleInvoices/createSaleInvoiceDraft',
  async (params, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const response = await setCreateSaleInvoiceDraft(selectedProjectId);

    console.log('response', response);

    return response;
  }
);
