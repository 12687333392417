import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { getProjectMediaTagsData } from '../services/getProjectMediaTagsData.service';

export const loadProjectMediaTagsData = createAsyncThunk(
  'mediaTags/loadProjectMediaTagsData',
  async (_, { getState }) => {
    const projectId = selectSelectedProjectId(getState());
    const response = await getProjectMediaTagsData(projectId);
    return response;
  }
);
