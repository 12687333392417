import { PAYMENT_TERM_OPTIONS } from 'constants/payment-term-options';
import dayjs from 'dayjs';

export const getCustomerChanges = (customer) => ({
  customerReferenceNumber: customer.customerReferenceNumber || '',
  customerInvoiceMessage: customer.invoiceMessage || '',
  reverseAlv: customer.reverseAlv || false,
  overheadPercentage: customer.overheadPercentage || 0,

  customer: customer.id,
  customerName: customer.name,
  customerNumber: customer.number,
  customerBusinessId: customer.customerBusinessId,
  customerVatNumber: customer.customerVatNumber,
  customerEmail: customer.email || '',
  customerPhone: customer.phone || '',

  customerStreet1: customer.street1 || '',
  customerStreet2: customer.street2 || '',
  customerCo: customer.co || '',
  customerPoBox: customer.poBox || '',
  customerPostCode: customer.postCode || '',
  customerCity: customer.city || '',
  customerCountry: customer.country || '',

  customerContactFirstName: customer.contactFirstName || '',
  customerContactLastName: customer.contactLastName || '',
  customerContactEmail: customer.contactEmail || '',
  customerContactPhone: customer.contactPhone || '',

  billingType: customer.billingType || 'email',

  customerEInvoiceAddress: customer.eInvoiceAddress || '',
  customerEInvoiceOperator: customer.eInvoiceOperator || '',

  customerBillingEmail: customer.billingEmail || '',

  customerBillingStreet1: customer.billingStreet1 || '',
  customerBillingStreet2: customer.billingStreet2 || '',
  customerBillingCo: customer.billingCo || '',
  customerBillingPoBox: customer.billingPoBox || '',
  customerBillingPostCode: customer.billingPostCode || '',
  customerBillingCity: customer.billingCity || '',
  customerBillingCountry: customer.billingCountry || '',
});

export const getPaymentTermChanges = (paymentTerm, invoiceDate) => {
  if (!paymentTerm && paymentTerm !== 0) return {};

  const termOption = PAYMENT_TERM_OPTIONS[paymentTerm];
  if (!termOption) return {};

  return {
    paymentTerm,
    dueDate: dayjs(invoiceDate).add(termOption.value, 'days').toISOString(),
  };
};
