import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { postCreateMediaTag } from '../services/postCreateMediaTag.service';
import { selectMediaTagInputValue } from '../selectors/mediaTags.selectors';
import { selectSelectedFilePermissionId } from 'scenes/ProjectGallery/store/selectors/project-gallery-base.selectors';

export const createProjectMediaTag = createAsyncThunk(
  'mediaTags/createProjectMediaTag',
  async (assignmentTagCreateData, { getState }) => {
    const projectId = selectSelectedProjectId(getState());

    const mediaTagName = selectMediaTagInputValue(getState());

    const selectedFilePermissionId = selectSelectedFilePermissionId(getState());

    let mediaTagAttributes;

    if (assignmentTagCreateData) {
      const { name, assignmentId, assignmentNumberPath } =
        assignmentTagCreateData;

      mediaTagAttributes = {
        name,
        fileId: selectedFilePermissionId,
        assignmentId,
        assignmentNumberPath,
      };
    } else {
      mediaTagAttributes = {
        name: mediaTagName,
        fileId: selectedFilePermissionId,
      };
    }

    const response = await postCreateMediaTag(projectId, mediaTagAttributes);
    return response;
  }
);
