import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { postAddMediaTagLink } from '../services/postAddMediaTagLink.service';
import { selectSelectedFilePermissionId } from 'scenes/ProjectGallery/store/selectors/project-gallery-base.selectors';

export const addMediaTagForFile = createAsyncThunk(
  'mediaTags/addMediaTagForFile',
  async (mediaTagId, { getState }) => {
    const projectId = selectSelectedProjectId(getState());

    const selectedFilePermissionId = selectSelectedFilePermissionId(getState());

    const response = await postAddMediaTagLink(
      projectId,
      selectedFilePermissionId,
      mediaTagId
    );

    return response;
  }
);
