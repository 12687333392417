import { round } from 'lodash-es';
import { enqueueSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectBankImagesUploadSignedUrl } from 'store/files/services/getProjectBankImagesUploadSignedUrl.service';
import getFileExtension from 'utils/getFileExtension';
import uuidv4 from 'uuid/v4';

import axios from 'axios';
import { createMediaFilePermissionToProjectBank } from 'store/filepermissions/thunks/createMediaFilePermissionToProjectBank.thunk';
import { selectMediaTagsToAddOnUploadByFileIndex } from 'store/mediaTags/selectors/mediaTags.selectors';

const useProjectImages = () => {
  const [imageFiles, setImageFiles] = useState([]);

  const [filesCount, setFilesCount] = useState(0);

  const [uploadProgress, setUploadProgress] = useState(0);

  const [uploadSizes, setUploadSizes] = useState({
    loaded: 0,
    total: 0,
  });

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const [filesUploadingCount, setFilesUploadingCount] = useState(0);

  const dispatch = useDispatch();

  const cancelTokenSource = useRef();

  const mediaTagsByFileIndex = useSelector(
    selectMediaTagsToAddOnUploadByFileIndex
  );

  const initializeState = () => {
    setFilesCount(0);
    setUploadSizes({ loaded: 0, total: 0 });
    setUploadProgress(0);
    setUploadDialogOpen(false);
    setFilesUploadingCount(0);
  };

  const onCancelAdding = () => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
    }
    initializeState();
    console.log('trying to close');
  };

  const { t } = useTranslation('common');

  const uploadImageBySignedUrl = async (imageFile, signedUrl) => {
    const fileType = imageFile.type;
    cancelTokenSource.current = axios.CancelToken.source();
    const options = {
      cancelToken: cancelTokenSource.current.token,
      headers: {
        'Content-Type': fileType,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        const percentage = (loaded / total) * 100;

        setUploadProgress(percentage);
        const loadedMB = round(loaded / (1024 * 1024), 2);
        const totalMB = round(total / (1024 * 1024), 2);
        setUploadSizes({
          loaded: loadedMB,
          total: totalMB,
        });
      },
    };
    try {
      await axios.put(signedUrl, imageFile, options);
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error('Upload canceled');
      } else {
        throw error;
      }
    }
  };

  const onUploadImages = async ({ recordId, assignmentId, projectId }) => {
    setFilesCount(imageFiles.length);
    setUploadDialogOpen(true);

    for (const [index, imageFile] of Object.entries(imageFiles)) {
      setUploadProgress(0);
      setFilesUploadingCount((prev) => prev + 1);
      const id = uuidv4();
      const fileExtension = getFileExtension(imageFile.name);

      const s3FileName = `${id}.${fileExtension}`;

      const signedUrl = await getProjectBankImagesUploadSignedUrl({
        projectId,
        fileName: s3FileName,
        contentType: imageFile.type,
      });

      console.log(signedUrl);

      try {
        await uploadImageBySignedUrl(imageFile, signedUrl);
        const fileData = {
          name: imageFile.name,
          s3FileName: s3FileName,
          contentType: imageFile.type,
          recordId,
          assignmentId,
          mediaTagsToAdd: mediaTagsByFileIndex[index],
        };
        // create filepermission.
        dispatch(
          createMediaFilePermissionToProjectBank({
            projectId,
            ...fileData,
          })
        );
      } catch (error) {
        console.log('Error during upload:', error);
        enqueueSnackbar(t('notifications.errors.add-file-error'), {
          variant: 'error',
        });
        break; // Break out of the loop if an error occurs
      }
    }
    initializeState();
    setUploadDialogOpen(false);
  };

  return {
    imageFiles,
    filesCount,
    uploadProgress,
    uploadSizes,
    uploadDialogOpen,
    filesUploadingCount,
    setImageFiles,
    onUploadImages,
    initializeState,
    onCancelAdding,
  };
};

export default useProjectImages;
