import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  removeMediaTagToAddFromFileOnUpload,
  setIsMediaTagInputActive,
  setMediaTagFilterInputValue,
  setMediaTagInputValue,
  setMediaTagToAddForFileOnUpload,
  setSelectedFilterMediaTagIds,
  setSelectedMediaTagFilteringType,
} from './actions/mediaTags.actions';
import { createProjectMediaTag } from './thunks/createProjectMediaTag.thunk';
import { loadProjectMediaTags } from './thunks/loadProjectMediaTags.thunk';
import { loadProjectMediaTagsData } from './thunks/loadProjectMediaTagsData.thunk';
import { addMediaTagForFile } from './thunks/addMediaTagForFile.thunk';
import { removeTagLinkFromFile } from './thunks/removeTagLinkFromFile.thunk';
import { enqueueSnackbar } from 'notistack';
import { setSelectedFilePermissionId } from 'scenes/ProjectGallery/store/actions/projectGallery.actions';
import { createMediaFilePermissionToProjectBank } from 'store/filepermissions/thunks/createMediaFilePermissionToProjectBank.thunk';
import { createProjectMediaTagOnFileAdd } from './thunks/createProjectMediaTagOnFileAdd.thunk';
import { saveImageInformationForUploadedFiles } from 'store/filepermissions/thunks/saveImageInformationForUploadedFiles.thunk';

const mediaTagsAdapter = createEntityAdapter();

const mediaTagLinksAdapter = createEntityAdapter();

export const mediaTagsSlice = createSlice({
  name: 'mediaTags',
  initialState: {
    mediaTags: mediaTagsAdapter.getInitialState(),
    mediaTagLinks: mediaTagLinksAdapter.getInitialState(),
    mediaTagInputValue: '',
    isMediaTagInputActive: false,
    isLoading: false,
    isCreatingOrUpdatingTags: false,
    selectedMediaTagFilteringType: 'OR',
    mediaTagFilterInputValue: '',
    selectedFilterMediaTagIds: [],
    mediaTagsToAddOnUploadByFileIndex: {},
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(setMediaTagInputValue, (state, action) => {
        state.mediaTagInputValue = action.payload;
      })
      .addCase(setIsMediaTagInputActive, (state, action) => {
        state.isMediaTagInputActive = action.payload;
      })
      .addCase(createProjectMediaTag.pending, (state, action) => {
        state.isCreatingOrUpdatingTags = true;
      })
      .addCase(createProjectMediaTag.fulfilled, (state, action) => {
        const { mediaTagItem, mediaTagLinkItem } = action.payload;

        mediaTagsAdapter.setOne(state.mediaTags, mediaTagItem);
        if (mediaTagLinkItem) {
          mediaTagLinksAdapter.setOne(state.mediaTagLinks, mediaTagLinkItem);
        }

        state.mediaTagInputValue = '';

        state.isCreatingOrUpdatingTags = false;
      })
      .addCase(createProjectMediaTag.rejected, (state, action) => {
        state.isCreatingOrUpdatingTags = false;

        enqueueSnackbar('Virhe tagin luonnissa', {
          variant: 'error',
        });
      })
      .addCase(createProjectMediaTagOnFileAdd.pending, (state, action) => {
        state.isCreatingOrUpdatingTags = true;
      })
      .addCase(createProjectMediaTagOnFileAdd.fulfilled, (state, action) => {
        const { mediaTagItem } = action.payload;

        const { fileIndex } = action.meta.arg;

        mediaTagsAdapter.setOne(state.mediaTags, mediaTagItem);

        state.isCreatingOrUpdatingTags = false;

        if (!state.mediaTagsToAddOnUploadByFileIndex[fileIndex]) {
          state.mediaTagsToAddOnUploadByFileIndex[fileIndex] = [];
        }
        state.mediaTagsToAddOnUploadByFileIndex[fileIndex].push(mediaTagItem);
      })
      .addCase(createProjectMediaTagOnFileAdd.rejected, (state, action) => {
        state.isCreatingOrUpdatingTags = false;

        enqueueSnackbar('Virhe tagin luonnissa', {
          variant: 'error',
        });
      })
      .addCase(addMediaTagForFile.pending, (state, action) => {
        state.isCreatingOrUpdatingTags = true;
      })
      .addCase(addMediaTagForFile.fulfilled, (state, action) => {
        mediaTagLinksAdapter.setOne(state.mediaTagLinks, action.payload);
        state.mediaTagInputValue = '';
        state.isCreatingOrUpdatingTags = false;
      })
      .addCase(addMediaTagForFile.rejected, (state, action) => {
        state.isCreatingOrUpdatingTags = false;
        enqueueSnackbar('Virhe tagin lisäyksessä', {
          variant: 'error',
        });
      })
      .addCase(loadProjectMediaTags.fulfilled, (state, action) => {
        mediaTagsAdapter.setMany(state.mediaTags, action.payload);
      })
      .addCase(loadProjectMediaTagsData.fulfilled, (state, action) => {
        const { mediaTags, mediaTagLinks } = action.payload;
        mediaTagsAdapter.setMany(state.mediaTags, mediaTags);
        mediaTagLinksAdapter.setMany(state.mediaTagLinks, mediaTagLinks);
      })
      .addCase(removeTagLinkFromFile.fulfilled, (state, action) => {
        const { id } = action.payload;

        mediaTagLinksAdapter.removeOne(state.mediaTagLinks, id);
      })
      .addCase(setSelectedFilePermissionId, (state, action) => {
        state.isMediaTagInputActive = false;
      })
      .addCase(setSelectedMediaTagFilteringType, (state, action) => {
        state.selectedMediaTagFilteringType = action.payload;
      })
      .addCase(setMediaTagFilterInputValue, (state, action) => {
        state.mediaTagFilterInputValue = action.payload;
      })
      .addCase(setSelectedFilterMediaTagIds, (state, action) => {
        state.selectedFilterMediaTagIds = action.payload;
      })
      .addCase(setMediaTagToAddForFileOnUpload, (state, action) => {
        const { fileIndex, mediaTag } = action.payload;

        if (!state.mediaTagsToAddOnUploadByFileIndex[fileIndex]) {
          state.mediaTagsToAddOnUploadByFileIndex[fileIndex] = [];
        }
        state.mediaTagsToAddOnUploadByFileIndex[fileIndex].push(mediaTag);

        state.mediaTagInputValue = '';
      })
      .addCase(
        createMediaFilePermissionToProjectBank.fulfilled,
        (state, action) => {
          state.mediaTagsToAddOnUploadByFileIndex = {};
        }
      )
      .addCase(removeMediaTagToAddFromFileOnUpload, (state, action) => {
        const { fileIndex, mediaTagId } = action.payload;

        const thisIndexMediaTags =
          state.mediaTagsToAddOnUploadByFileIndex[fileIndex];

        const filteredMediaTags = thisIndexMediaTags.filter(
          (mediaTag) => mediaTag.id !== mediaTagId
        );

        state.mediaTagsToAddOnUploadByFileIndex[fileIndex] = filteredMediaTags;
      })
      .addCase(
        saveImageInformationForUploadedFiles.fulfilled,
        (state, action) => {
          state.mediaTagsToAddOnUploadByFileIndex = {};
        }
      );
  },
});
