import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { postCreateMediaTag } from '../services/postCreateMediaTag.service';

export const createProjectMediaTagOnFileAdd = createAsyncThunk(
  'mediaTags/createProjectMediaTagOnFileAdd',
  async (assignmentTagCreateData, { getState }) => {
    const projectId = selectSelectedProjectId(getState());

    let mediaTagAttributes;

    const { name, assignmentId } = assignmentTagCreateData;

    if (assignmentId) {
      mediaTagAttributes = {
        name,
        assignmentId,
      };
    } else {
      mediaTagAttributes = {
        name,
      };
    }

    const response = await postCreateMediaTag(projectId, mediaTagAttributes);
    return response;
  }
);
