import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';
import { getBusinessShortTermProjects } from '../services/getBusinessShortTermProjects.service';

export const loadBusinessShortTermProjects = createAsyncThunk(
  'shortTermProjects/loadBusinessShortTermProjects',
  async (_, { getState }) => {
    const selectedProject = selectSelectedProject(getState());

    const response = await getBusinessShortTermProjects(
      selectedProject.business_id
    );

    return response;
  }
);
