import { createAsyncThunk } from '@reduxjs/toolkit';
import { loadProjectAndSubProjectBulletins } from 'scenes/BulletinBoard/store/thunks/loadProjectAndSubProjectBulletins';
import { selectIsAdminInSelectedProject } from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';
import { loadAllUserProjectsPermissions } from 'scenes/Permissions/store/thunks/loadAllUserProjectsPermissions.thunk';
import { setSelectedProjectId } from 'scenes/Project/store/actions/projectActions';
import { setProject } from 'store/actions/setProject';
import { loadApprovalGroupsByProject } from 'store/approvals/thunks/loadApprovalGroupsByProject';
import { loadAllowancesOfCurrentUserEmployers } from 'store/employerAllowances/thunks/loadAllowancesOfCurrentUserEmployers.thunk';
import { clearSelectedRecords } from 'store/records/actions/records.actions';
import {
  setPersonalWorkCalendarMode,
  setSelectionMode,
} from '../actions/demoActions';
import { loadAdminInitialDataInTimeFrame } from './loadAdminInitialDataInTimeFrame';
import { loadInitialCalendarData } from './loadInitialCalendarData.thunk';
import { loadInitialCalendarDataForAdmin } from './loadInitialCalendarDataForAdmin.thunk';

export const initWorkCalendar = createAsyncThunk(
  'project/initWorkCalendar',
  async ({ projectId, viewMode }, { dispatch, getState }) => {
    dispatch(setProject({ id: projectId }));
    dispatch(setSelectedProjectId(projectId));
    dispatch(clearSelectedRecords());
    if (viewMode === 'invoice') {
      dispatch(setSelectionMode(true));
    } else {
      dispatch(setSelectionMode(false));
    }
    dispatch(loadProjectAndSubProjectBulletins());
    dispatch(setPersonalWorkCalendarMode(false));
    dispatch(loadAllowancesOfCurrentUserEmployers());

    await dispatch(loadAllUserProjectsPermissions());
    dispatch(loadApprovalGroupsByProject(projectId));

    const state = getState();
    const isAdminInSelectedProject = selectIsAdminInSelectedProject(state);

    if (isAdminInSelectedProject) {
      await dispatch(loadAdminInitialDataInTimeFrame());
      dispatch(loadInitialCalendarDataForAdmin());
    } else {
      await dispatch(loadInitialCalendarData());
    }
  }
);
