import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectMediaTagsToAddOnUploadByFileIndex } from 'store/mediaTags/selectors/mediaTags.selectors';
import { postSaveFilepermissionInformationForUploadedFiles } from '../services/postSaveFilepermissionInformationForUploadedFiles.service';

export const saveImageInformationForUploadedFiles = createAsyncThunk(
  'filepermissions/saveImageInformationForUploadedFiles',
  async (_, { getState }) => {
    const mediaTagsToAddByFileId = selectMediaTagsToAddOnUploadByFileIndex(
      getState()
    );

    const response = await postSaveFilepermissionInformationForUploadedFiles(
      mediaTagsToAddByFileId
    );
    return response;
  }
);
