import { createAsyncThunk } from '@reduxjs/toolkit';
import { setDeleteSaleInvoiceDraft } from 'store/invoices/services/setDeleteSaleInvoiceDraft';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const deleteSaleInvoiceDraft = createAsyncThunk(
  'saleInvoices/deleteSaleInvoiceDraft',
  async (params, { getState, dispatch }) => {
    console.log('params', params);
    const { saleInvoice } = params;

    const saleInvoiceId = saleInvoice.id;

    const selectedProjectId = selectSelectedProjectId(getState());
    if (!saleInvoiceId) {
      throw new Error('No sale invoice id provided');
    }

    console.log('Updating sale invoice draft', saleInvoice);

    const response = await setDeleteSaleInvoiceDraft(
      selectedProjectId,
      saleInvoiceId,
    );

    return response;
  }
);
