import { createSelector } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

import { groupBy, orderBy, uniq } from 'lodash-es';
import { selectSelectedFilePermissionId } from 'scenes/ProjectGallery/store/selectors/project-gallery-base.selectors';
import { selectSelectedProjectsAssignmentsArrayWithNumberPath } from 'store/assignments/selectors/assignment.selectors';

const selectMediaTagsState = (state) => state.mediaTags;

export const selectMediaTagsEntities = createSelector(
  [selectMediaTagsState],
  (state) => state.mediaTags.entities
);

export const selectMediaTagsArray = createSelector(
  [selectMediaTagsEntities],
  (entities) => Object.values(entities)
);

export const selectMediaTagLinkEntities = createSelector(
  [selectMediaTagsState],
  (state) => state.mediaTagLinks.entities
);

export const selectIsCreatingOrUpdatingTags = createSelector(
  [selectMediaTagsState],
  (state) => state.isCreatingOrUpdatingTags
);

export const selectSelectedMediaTagFilteringType = createSelector(
  [selectMediaTagsState],
  (state) => state.selectedMediaTagFilteringType
);

export const selectSelectedFilterMediaTagIds = createSelector(
  [selectMediaTagsState],
  (state) => state.selectedFilterMediaTagIds
);

export const selectMediaTagLinksArray = createSelector(
  [selectMediaTagLinkEntities],
  (entities) => Object.values(entities)
);

export const selectMediaTagLinksGroupedByFileId = createSelector(
  [selectMediaTagLinksArray],
  (mediaTagLinks) => groupBy(mediaTagLinks, 'fileId')
);

export const selectMediaTagsToAddOnUploadByFileIndex = createSelector(
  [selectMediaTagsState],
  (state) => state.mediaTagsToAddOnUploadByFileIndex
);

export const selectMediaTagLinksByFileId = createSelector(
  [selectMediaTagLinksGroupedByFileId, (_, args) => args],
  (groupedMediaTagLinks, fileId) => groupedMediaTagLinks[fileId]
);

export const selectSelectedProjectMediaTagLinks = createSelector(
  [selectMediaTagLinksArray, selectSelectedProjectId],
  (mediaTagLinks, selectedProjectId) =>
    mediaTagLinks.filter(
      (mediaTagLink) => mediaTagLink.projectId === selectedProjectId
    )
);

export const selectSelectedProjectMediaTags = createSelector(
  [selectMediaTagsArray, selectSelectedProjectId],
  (mediaTags, projectId) =>
    mediaTags.filter((mediaTag) => mediaTag.projectId === projectId)
);

export const selectProjectMediaTagsByProjectId = createSelector(
  [selectMediaTagsArray, (_, args) => args],
  (mediaTags, projectId) =>
    mediaTags.filter((mediaTag) => mediaTag.projectId === projectId)
);

export const selectSelectedProjectGroupedMediaTags = createSelector(
  [selectSelectedProjectMediaTags],
  (mediaTags) => {
    const { sortedManualTagOptions, sortedAssignmentTagOptions } =
      returnTagOptionsWithGroupIdentifier(mediaTags, []);

    return [...sortedManualTagOptions, ...sortedAssignmentTagOptions];
  }
);

export const selectMediaTagInputValue = createSelector(
  [selectMediaTagsState],
  (state) => state.mediaTagInputValue
);

export const selectMediaTagFilterInputValue = createSelector(
  [selectMediaTagsState],
  (state) => state.mediaTagFilterInputValue
);

export const selectIsMediaTagInputActive = createSelector(
  [selectMediaTagsState],
  (state) => state.isMediaTagInputActive
);

export const selectMediaTagFilteredFileIds = createSelector(
  [
    selectSelectedFilterMediaTagIds,
    selectSelectedProjectMediaTagLinks,
    selectSelectedMediaTagFilteringType,
  ],
  (filterMediaTagIds, selectedProjectMediaTagLinks, filteringType) => {
    if (filteringType === 'OR') {
      const mediaTagLinksWithSelectedMediaTagFilters =
        selectedProjectMediaTagLinks.filter((mediaTagLink) =>
          filterMediaTagIds.includes(mediaTagLink.mediaTagId)
        );

      const uniqueFileIdsWithFilterMediaTags = uniq(
        mediaTagLinksWithSelectedMediaTagFilters.map(
          (mediaTagLink) => mediaTagLink.fileId
        )
      );

      return uniqueFileIdsWithFilterMediaTags;
    } else if (filteringType === 'AND') {
      let fileIdGroupedMediaTags = {};

      selectedProjectMediaTagLinks.forEach((mediaTagLink) => {
        const { fileId, mediaTagId } = mediaTagLink;

        if (!fileIdGroupedMediaTags[fileId]) {
          fileIdGroupedMediaTags[fileId] = [mediaTagId];
        } else {
          fileIdGroupedMediaTags[fileId].push(mediaTagId);
        }
      });

      const fileIdsWithAllSelectedFilterMediaTags = [];

      Object.entries(fileIdGroupedMediaTags).forEach(
        ([fileId, mediaTagIds]) => {
          if (filterMediaTagIds.every((id) => mediaTagIds.includes(id))) {
            fileIdsWithAllSelectedFilterMediaTags.push(fileId);
          }
        }
      );

      return fileIdsWithAllSelectedFilterMediaTags;
    }
  }
);

export const selectSelectedFilepermissionMediaTags = createSelector(
  [
    selectSelectedFilePermissionId,
    selectMediaTagLinksGroupedByFileId,
    selectMediaTagsEntities,
  ],
  (selectedFileId, groupedMediaTagLinks, mediaTags) => {
    const filePermissionTagLinks = groupedMediaTagLinks[selectedFileId]
      ? groupedMediaTagLinks[selectedFileId]
      : [];

    const tagLinksWithTagName = filePermissionTagLinks.map((tagLink) => ({
      ...tagLink,
      name: mediaTags[tagLink.mediaTagId]?.name,
      assignmentNumberPath: mediaTags[tagLink.mediaTagId]?.assignmentNumberPath,
    }));

    return tagLinksWithTagName;
  }
);

const selectFilteredAssignmentTagOptions = createSelector(
  [
    selectSelectedProjectsAssignmentsArrayWithNumberPath,
    selectSelectedFilepermissionMediaTags,
    selectSelectedProjectMediaTags,
  ],
  (assignments, selectedFileMediaTags, selectedProjectMediaTags) => {
    const mediaTagsNames = selectedFileMediaTags.map(
      (mediaTag) => mediaTag.name
    );

    const assignmentIdsThatHaveAlreadyBeenMadeIntoTags =
      selectedProjectMediaTags.map((mediaTag) => mediaTag.assignmentId);

    const notYetUsedAssignments = assignments
      ? assignments.filter(
          (assignment) =>
            !mediaTagsNames.includes(assignment.name) &&
            !assignmentIdsThatHaveAlreadyBeenMadeIntoTags.includes(
              assignment.id
            )
        )
      : [];

    return notYetUsedAssignments;
  }
);

const selectFilteredProjectMediaTagOptions = createSelector(
  [selectSelectedProjectMediaTags, selectSelectedFilepermissionMediaTags],
  (mediaTags, selectedFilePermissionMediaTags) => {
    const mediaTagIdsAlreadyInUse = selectedFilePermissionMediaTags.map(
      (tag) => tag.mediaTagId
    );

    const filteredProjectMediaTags = mediaTags.filter(
      (mediaTag) => !mediaTagIdsAlreadyInUse.includes(mediaTag.id)
    );

    return filteredProjectMediaTags;
  }
);

export const selectGroupedProjectMediaTags = createSelector(
  [selectFilteredProjectMediaTagOptions, selectFilteredAssignmentTagOptions],
  (mediaTags, assignments) => {
    const { sortedManualTagOptions, sortedAssignmentTagOptions } =
      returnTagOptionsWithGroupIdentifier(mediaTags, assignments);

    return [...sortedManualTagOptions, ...sortedAssignmentTagOptions];
  }
);

export const selectFileUploadProjectMediaTagOptions = createSelector(
  [selectMediaTagsArray, selectFilteredAssignmentTagOptions, (_, args) => args],
  (mediaTags, assignments, projectId) => {
    const projectMediaTags = mediaTags.filter(
      (mediaTag) => mediaTag.projectId === projectId
    );

    const { sortedManualTagOptions, sortedAssignmentTagOptions } =
      returnTagOptionsWithGroupIdentifier(projectMediaTags, assignments);

    return [...sortedManualTagOptions, ...sortedAssignmentTagOptions];
  }
);

export const selectMediaTagsToAddByFileIndex = createSelector(
  [selectMediaTagsToAddOnUploadByFileIndex, (_, args) => args],
  (mediaTagsByFileId, fileIndex) =>
    mediaTagsByFileId[fileIndex] ? mediaTagsByFileId[fileIndex] : []
);

const returnTagOptionsWithGroupIdentifier = (mediaTags, assignments) => {
  let manualTagOptions = [];
  let assignmentTagOptions = [];

  [...mediaTags, ...assignments].forEach((item) => {
    if (item.type === 'MEDIA_TAG' && !item.assignmentId) {
      manualTagOptions.push({
        ...item,
        groupIdentifier: 'Vapaamuotoiset tunnisteet',
      });
    } else {
      assignmentTagOptions.push({
        ...item,
        groupIdentifier: 'Tunnisteet litteroista',
      });
    }
  });

  const sortedManualTagOptions = orderBy(
    manualTagOptions,
    (value) => value.name.toLowerCase(),
    'asc'
  );

  const sortedAssignmentTagOptions = orderBy(
    assignmentTagOptions,
    'assignmentNumberPath'
  );

  return { sortedManualTagOptions, sortedAssignmentTagOptions };
};
