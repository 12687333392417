import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProject } from '../selectors/projectsSelectors';
import { getIntegrationPartnerByBusinessId } from '../services/getIntegrationPartnerByBusinessId';

export const loadBusinessIntegrationPartnersForAnyProject = createAsyncThunk(
  'projects/loadBusinessIntegrationPartnersForAnyProject',
  async (_, { getState }) => {
    const selectedProject = selectSelectedProject(getState());

    const businessId = selectedProject.business_id;

    if(!businessId) {
      return { businessId, integrationPartner: null };
    }
    const integrationPartner = await getIntegrationPartnerByBusinessId(
      selectedProject.business_id
    );

    return { businessId, integrationPartner };
  }
);
