import { Button, Grid, makeStyles, useTheme } from '@material-ui/core';
import { mdiCamera } from '@mdi/js';
import Icon from '@mdi/react';
import Input from 'components/Input/Input';
import FileIcon from 'mdi-material-ui/FileOutline';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageSelectImageMediaTags } from './ImageSelectImageMediaTags/ImageSelectImageMediaTags';

const useStyles = makeStyles((theme) => ({
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  addPhotoButton: {
    backgroundColor: theme.palette.buttonPrimary,
    borderRadius: 4,
    boxSizing: 'border-box',
    textTransform: 'none',
    boxShadow: theme.shadows[1],
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  icon: {
    width: '100%',
    height: '100%',
  },
}));

export default function ImageSelectComponent({
  setImageFiles,
  disabled,
  projectId,
  initOnRender,
}) {
  const { t } = useTranslation('common');

  const [previewImages, setPreviewImages] = useState([]);
  const classes = useStyles();
  const theme = useTheme();

  const handleSelectPicture = async (event) => {
    const files = event.target.files;
    setImageFiles((oldFiles) => [...oldFiles, ...files]);
    let previewObjects = [];
    Object.values(files).forEach((file) => {
      const objectUrl = URL.createObjectURL(file);
      const previewObject = { url: objectUrl, type: file.type };

      previewObjects.push(previewObject);
    });
    setPreviewImages((oldArray) => [...oldArray, ...previewObjects]);
  };

  useEffect(() => {
    if (initOnRender) {
      setTimeout(() => {
        const input = document.getElementById('image-select');
        input.click();
      }, 200);
    }
  }, [initOnRender]);

  const supportedImageTypes = [
    'image/jpg',
    'image/png',
    'image/jpeg',
    'image/avif',
  ];

  const previewComponentToRender = (file) => {
    const isImage = file.type.startsWith('image');

    const isSupportedImage = isImage && supportedImageTypes.includes(file.type);

    const isVideo = file.type.startsWith('video');
    const isOtherFile = !isImage && !isVideo;

    if (isImage) {
      const fileUrl = isSupportedImage
        ? file.url
        : theme.component.imagePlaceholder;
      return <img className={classes.image} src={fileUrl} />;
    } else if (isVideo) {
      return (
        <video className={classes.video} muted key={file.url}>
          <source src={file.url} />
        </video>
      );
    } else if (isOtherFile) {
      return <FileIcon className={classes.icon} />;
    }
  };

  return (
    <Grid container spacing={1}>
      {previewImages.map((file, index) => {
        return (
          <Grid item xs={12} sm={6} key={index}>
            <div
              style={{
                backgroundSize: 'contain',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                width: '100%',
                height: '128px',
                marginBottom: '10px',
              }}
            >
              {previewComponentToRender(file)}
            </div>
            <ImageSelectImageMediaTags
              fileIndex={index}
              projectId={projectId}
            ></ImageSelectImageMediaTags>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Input
          htmlFor="add-record-picture"
          onUploadImages={handleSelectPicture}
          clockOut={true}
          disabled={disabled}
        >
          <label htmlFor="add-record-picture">
            <Button
              component="span"
              disabled={disabled}
              className={classes.addPhotoButton}
              startIcon={
                <Icon path={mdiCamera} size={1} className={classes.icon} />
              }
              id="image-select"
            >
              {t('dashboard.add_new_picture')}
            </Button>
          </label>
        </Input>
      </Grid>
    </Grid>
  );
}
