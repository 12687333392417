import { createAsyncThunk } from '@reduxjs/toolkit';

import { getUserData } from 'store/userProfile/services/getUserData.service';

export const loadUserData = createAsyncThunk(
  'users/loadUserData',
  async (params) => {
    const { userId } = params;
    const userData = await getUserData(userId);

    return userData;
  }
);
