import { createSelector } from '@reduxjs/toolkit';
import { groupBy, orderBy, sortBy, uniqBy } from 'lodash-es';
import { returnResourcesFromSubProjects } from 'scenes/Permissions/utils/returnResourcesFromSubProjects.util';
import {
  selectProjects,
  selectProjectsArray,
  selectSelectedProject,
  selectSelectedProjectId,
} from 'store/projects/selectors/projectsSelectors';
import { selectAllResources } from 'store/resources/resourceSliceSelectors';
import i18n from 'i18next';

const selectProjectResourcesState = (state) => state.projectResources;

export const selectProjectResourcesEmployeeUsers = createSelector(
  [selectProjectResourcesState],
  (state) => state.projectEmployeeUsers.entities
);

export const selectProjectResourcesEmployeeUsersArray = createSelector(
  [selectProjectResourcesEmployeeUsers],
  (employeeUsers) => Object.values(employeeUsers)
);

export const selectSelectedProjectResourcesUserId = createSelector(
  [selectProjectResourcesState],
  (state) => state.selectedProjectResourcesUserId
);

export const selectSelectedProjectResource = createSelector(
  [selectProjectResourcesState],
  (state) => {
    return state.selectedProjectResource;
  }
);

export const selectProjectResourcesEmployeeSearchString = createSelector(
  [selectProjectResourcesState],
  (state) => state.projectResourcesEmployeeSearchString
);

export const selectIsProjectResorcesEmployeesLoading = createSelector(
  [selectProjectResourcesState],
  (state) => state.loading
);

export const selectSearchFilteredProjectResourcesEmployeeUsers = createSelector(
  [
    selectProjectResourcesEmployeeUsersArray,
    selectProjectResourcesEmployeeSearchString,
  ],
  (employeeUsers, searchString) => {
    const searchFilteredUsers = employeeUsers.filter(
      (user) =>
        user.firstName.toLowerCase().includes(searchString) ||
        user.lastName.toLowerCase().includes(searchString)
    );

    const sortedUsers = orderBy(
      searchFilteredUsers,
      [(user) => user.firstName],
      ['asc']
    );

    return sortedUsers;
  }
);

export const selectUserResourcesViewResources = createSelector(
  [
    selectAllResources,
    selectSelectedProjectId,
    selectSelectedProject,
    selectProjectsArray,
    selectProjects,
  ],
  (allResources, selectedProjectId, selectedProject, projects, projectsMap) => {
    const sortedResources = sortBy(allResources, ['firstName']);

    let projectResources = [];

    if (selectedProject.businessTypeEnum === 'EMPLOYER') {
      if (selectedProject.business_id) {
        const resourcesInBusinessProjects = sortedResources.filter(
          (resource) =>
            resource.projectBusinessId === selectedProject.business_id
        );

        projectResources = resourcesInBusinessProjects;
      } else {
        const subProjectResources = returnResourcesFromSubProjects(
          projects,
          selectedProjectId,
          sortedResources
        );

        const employerProjectResources = (projectResources =
          sortedResources.filter(
            (resource) => resource.project === selectedProjectId
          ));
        projectResources = [
          ...subProjectResources,
          ...employerProjectResources,
        ];
      }
    } else {
      projectResources = sortedResources.filter(
        (resource) => resource.project === selectedProjectId
      );
    }

    const uniqueProjectResources = uniqBy(projectResources, (resource) =>
      [resource.user, resource.employerBusinessId].join()
    );

    const resourcesWithEmployerData = uniqueProjectResources.map((resource) => {
      const employerData = projectsMap[resource.employer];

      resource = {
        ...resource,
        employerData: employerData,
      };

      return resource;
    });

    // For each resource.user and resource..employerBusinessId combination I want only one resource
    const uniqueResourcesWithEmployerData = uniqBy(
      resourcesWithEmployerData,
      (resource) => `${resource.user}_${resource.employerBusinessId}`
    );
    console.log("uniqueResourcesWithEmployerData:", uniqueResourcesWithEmployerData)

    return uniqueResourcesWithEmployerData;
  }
);

const selectSearchFilteredProjectResources = createSelector(
  [selectUserResourcesViewResources, selectProjectResourcesEmployeeSearchString],
  (resources, searchText) => {
    if (!searchText) {
      return resources;
    }

    const filteredResources = resources.filter((resource) => {
      const resourceEmployer = resource.employerData?.name;
      const business_id = resource.employerData?.business_id || '';

      const searchableValue = `${resource.firstName} ${resource.lastName} ${resourceEmployer} ${business_id}`;

      const splitValue = searchText.split(' ');
      const splitSearchableValue = searchableValue.split(' ');

      const searchResult = splitValue.every((splitItem) =>
        splitSearchableValue.some((data) =>
          data.toLowerCase().includes(splitItem)
        )
      );

      if (searchResult) {
        return resource;
      }
    });

    return filteredResources;
  }
);

export const selectProjectResourcesViewEmployerGroupedResources =
  createSelector([selectSearchFilteredProjectResources], (resources) =>
    returnEmployerGroupedResources(resources)
  );

const returnEmployerGroupedResources = (resources) => {
  console.log("resources:", resources)
  const groupedByEmployer = groupBy(resources, 'employerData.business_id');

  groupedByEmployer['RESIDENTS'] = [];
  groupedByEmployer['MyLixani'] = [];

  const undefinedEmployerResources = groupedByEmployer['undefined']
    ? groupedByEmployer['undefined']
    : [];

  undefinedEmployerResources.forEach((resource) => {
    if (resource.employmentType === 'RESIDENT') {
      groupedByEmployer['RESIDENTS'].push(resource);
    } else {
      groupedByEmployer['MyLixani'].push(resource);
    }
  });

  delete groupedByEmployer['undefined'];

  const returnSortingName = (name) => {
    if (name === 'RESIDENTS') {
      return 'zzzz';
    } else if (name === 'MyLixani') {
      return 'yyyy';
    } else {
      return name.toLowerCase();
    }
  };

  const groupedByEmployerArray = Object.entries(groupedByEmployer).map(
    ([key, value]) => {
      const resources = value;

      const resourceWithFoundEmployerWithName = resources.find(
        (resource) => resource.employerData && resource.employerData.name
      );

      const resourceWithFoundBusinessId = resources.find(
        (resource) => resource.employerData && resource.employerData.business_id
      );

      const isResidents = key === 'RESIDENTS';
      const isMyLixani = key === 'MyLixani';
      const name = isResidents
        ? i18n.t('calendar.residents')
        : isMyLixani
          ? 'MyLixani'
          : resourceWithFoundEmployerWithName?.employerData.name;

      const sortingName =
        key !== 'RESIDENTS' && key !== 'MyLixani'
          ? returnSortingName(name)
          : returnSortingName(key);

      const groupIndex =
        key !== 'RESIDENTS' && key !== 'MyLixani'
          ? resourceWithFoundBusinessId?.employerData.business_id
          : key;

      return {
        name: name,
        sortingName,
        business_id: resourceWithFoundBusinessId?.employerData.business_id,
        employeeResources: resources,
        groupIndex,
      };
    }
  );

  const sorted = sortBy(groupedByEmployerArray, ['sortingName']);

  return sorted;
};
