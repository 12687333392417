import { createAction } from '@reduxjs/toolkit';

export const setMediaTagInputValue = createAction(
  'mediaTags/setMediaTagInputValue'
);

export const setIsMediaTagInputActive = createAction(
  'mediaTags/setIsMediaTagInputActive'
);

export const setSelectedMediaTagFilteringType = createAction(
  'mediaTags/setSelectedMediaTagFilteringType'
);

export const setMediaTagFilterInputValue = createAction(
  'mediaTags/setMediaTagFilterInputValue'
);

export const setSelectedFilterMediaTagIds = createAction(
  'mediaTags/setSelectedFilterMediaTagIds'
);

export const setMediaTagToAddForFileOnUpload = createAction(
  'mediaTags/setMediaTagToAddForFileOnUpload'
);

export const removeMediaTagToAddFromFileOnUpload = createAction(
  'mediaTags/removeMediatagToAddFromFileOnUpload'
);
