import { createSlice } from '@reduxjs/toolkit';
import React from 'react';

import { enqueueSnackbar } from 'notistack';
import {
  openFileUploadActionSnackbar,
  setIsAfterFileUploadEditDialogOpen,
} from './actions/filepermissions.actions';
import { reactToFilepermission } from './thunks/reactToFilepermission';
import { FileUploadActionSnackbarButtons } from 'components/FileUploadActionSnackbar/FileUploadActionSnackbarButtons';
import { createMediaFilePermissionToProjectBank } from './thunks/createMediaFilePermissionToProjectBank.thunk';
import { saveImageInformationForUploadedFiles } from './thunks/saveImageInformationForUploadedFiles.thunk';

export const filepermissionsSlice = createSlice({
  name: 'filepermissions',
  initialState: {
    loading: false,
    isAfterFileUploadEditDialogOpen: false,
    recentlyUploadedFiles: [],
    isUpdatingFilePermissionData: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(reactToFilepermission.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(reactToFilepermission.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(reactToFilepermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(openFileUploadActionSnackbar, (state) => {
        const action = () => {
          return (
            <FileUploadActionSnackbarButtons></FileUploadActionSnackbarButtons>
          );
        };

        enqueueSnackbar('Kuva lisätty', {
          action,
          variant: 'success',
        });
      })
      .addCase(setIsAfterFileUploadEditDialogOpen, (state, action) => {
        if (action.payload === false) {
          state.recentlyUploadedFiles = [];
        }
        state.isAfterFileUploadEditDialogOpen = action.payload;
      })
      .addCase(
        createMediaFilePermissionToProjectBank.fulfilled,
        (state, action) => {
          const createdFilePermission = action.payload;

          if (createdFilePermission.file.mimeType.includes('image')) {
            state.recentlyUploadedFiles.push(createdFilePermission);
          }
        }
      )
      .addCase(
        saveImageInformationForUploadedFiles.pending,
        (state, action) => {
          state.isUpdatingFilePermissionData = true;
        }
      )
      .addCase(
        saveImageInformationForUploadedFiles.fulfilled,
        (state, action) => {
          state.isAfterFileUploadEditDialogOpen = false;
          state.recentlyUploadedFiles = [];
          state.isUpdatingFilePermissionData = false;

          enqueueSnackbar('Tunnisteet lisätty', {
            variant: 'success',
          });
        }
      )
      .addCase(
        saveImageInformationForUploadedFiles.rejected,
        (state, action) => {
          state.isUpdatingFilePermissionData = false;
          enqueueSnackbar('Virhe tunnisteiden lisäyksessä', {
            variant: 'error',
          });
        }
      );
  },
});
