import { createAsyncThunk } from '@reduxjs/toolkit';
import { copyTimelineService } from 'scenes/Timelines/services/copyTimeline.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const copyTimeline = createAsyncThunk(
  'timelines/copyTimeline',
  async (timelineId, { getState, rejectWithValue }) => {
    const state = getState();
    const projectId = selectSelectedProjectId(state);
    try {
      const response = await copyTimelineService(projectId, timelineId);
      return response;
    } catch (error) {
      console.error('Failed to update timeline:', error);
      return rejectWithValue(error.response.data);
    }
  }
);
