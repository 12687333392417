import { createAsyncThunk } from '@reduxjs/toolkit';

import { getUserDataWithResources } from 'store/userProfile/services/getUserDataWithResources.service';

export const loadUserDataWithResources = createAsyncThunk(
  'users/loadUserDataWithResources',
  async (params) => {
    const { userId } = params;
    const userData = await getUserDataWithResources(userId);

    return userData;
  }
);
