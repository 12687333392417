import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { deleteTagLinkItem } from '../services/deleteTagLinkItem.service';

export const removeTagLinkFromFile = createAsyncThunk(
  'mediaTags/removeTagLinkFromFile',
  async (tagLinkId, { getState }) => {
    const projectId = selectSelectedProjectId(getState());

    const response = await deleteTagLinkItem(projectId, tagLinkId);
    return response;
  }
);
