import { Chip } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMediaTagsToAddByFileIndex } from 'store/mediaTags/selectors/mediaTags.selectors';
import { ImageSelectAddMediaTag } from './ImageSelectAddMediaTag/ImageSelectAddMediaTag';
import { removeMediaTagToAddFromFileOnUpload } from 'store/mediaTags/actions/mediaTags.actions';

export const ImageSelectImageMediaTags = ({ fileIndex, projectId }) => {
  const imageTagsToAdd = useSelector((state) =>
    selectMediaTagsToAddByFileIndex(state, fileIndex)
  );

  const dispatch = useDispatch();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'end',
        width: '100%',
        flexWrap: 'wrap',
        gap: '3px',
        alignItems: 'center',
      }}
    >
      {imageTagsToAdd.map((tag) => (
        <Chip
          key={tag.id}
          color="primary"
          label={`${tag.assignmentNumberPath ? tag.assignmentNumberPath : ''} ${
            tag.name
          }`}
          onDelete={() =>
            dispatch(
              removeMediaTagToAddFromFileOnUpload({
                fileIndex,
                mediaTagId: tag.id,
              })
            )
          }
          style={{ opacity: 0.9, fontSize: '14px' }}
        ></Chip>
      ))}
      <ImageSelectAddMediaTag
        fileIndex={fileIndex}
        projectId={projectId}
      ></ImageSelectAddMediaTag>
    </div>
  );
};
