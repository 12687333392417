import { createAsyncThunk } from '@reduxjs/toolkit';
import { generateSalaryRowDataFromRecords } from './generateSalaryRowDataFromRecords';
import { generateSalaryRows } from './generateSalaryRows';
import { createBalancoXlsx } from './createBalancoXlsx.thunk';

export const generateBalancoSalaryReport = createAsyncThunk(
  'workCalendar/generateBalancoSalaryReport',
  async (dialogSettings, { dispatch }) => {
    await dispatch(generateSalaryRowDataFromRecords(dialogSettings));

    await dispatch(generateSalaryRows());

    await dispatch(createBalancoXlsx());
  }
);
