import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCustomersByBusinessId } from '../services/getCustomersByBusinessId.service';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';

export const loadCustomersBySelectedProjectBusinessId = createAsyncThunk(
  'customers/loadCustomersBySelectedProjectBusinessId',
  async (_, { getState }) => {
    const state = getState();

    const selectedProject = selectSelectedProject(state);
    const businessId = selectedProject?.business_id;

    const response = await getCustomersByBusinessId(businessId);
    return response;
  }
);
