import { createAction } from '@reduxjs/toolkit';

export const updateDraftInvoice = createAction(
  'saleInvoices/updateDraftInvoice',
  ({ invoiceId, changes }) => ({
    payload: { invoiceId, changes },
  })
);

export const updateDraftInvoiceRow = createAction(
  'saleInvoices/updateDraftInvoiceRow',
  ({ invoiceId, rowId, changes }) => ({
    payload: { invoiceId, rowId, changes },
  })
);

export const addDraftInvoiceRow = createAction(
  'saleInvoices/addDraftInvoiceRow',
  ({ invoiceId, row }) => ({
    payload: { invoiceId, row },
  })
);

export const addDraftInvoiceRows = createAction(
  'saleInvoices/addDraftInvoiceRows',
  ({ invoiceId, rows }) => ({
    payload: { invoiceId, rows },
  })
);

export const setActiveSaleInvoiceDraft = createAction(
  'saleInvoices/setActiveSaleInvoiceDraft',
  ({ invoiceId }) => ({
    payload: { invoiceId },
  })
);

export const resetSaleInvoiceChanges = createAction(
  'saleInvoices/resetSaleInvoiceChanges',
  ({ invoiceId }) => ({
    payload: { invoiceId },
  })
);
