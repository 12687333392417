import { createSlice } from '@reduxjs/toolkit';
import { loadAssignmentsForProject } from 'store/assignments/thunks/loadAssignmentsForProject';
import { loadAssignmentsForProjects } from 'store/assignments/thunks/loadAssignmentsForProjects';
import { loadProjectAndSubProjectAssignments } from 'store/assignments/thunks/loadProjectAndSubProjectAssignments';
import * as filepermissionActions from 'store/filepermissions/actions/filepermissions.actions';
import { editRecords } from 'store/records/thunks/editRecords.thunk';
import * as demoActions from './store/actions/demoActions';
import {
  assignmentsAdapter,
  filePermissionsAdapter,
  workDemoEmployersAdapter,
  workDemoProjectsAdapter,
  workDemoResourcesAdapter,
} from './store/selectors/workDemoSliceSelectors';
import { initWorkCalendar } from './store/thunks/initWorkCalendar.thunk';
import { loadAdminCalendarDataInTimeframe } from './store/thunks/loadAdminCalendarDataInTimeframe.thunk';
import { loadAdminCalendarDataInTimeframeWithSearchParams } from './store/thunks/loadAdminCalendarDataInTimeframeWithSearchParams.thunk';
import { loadAdminInitialDataInTimeFrame } from './store/thunks/loadAdminInitialDataInTimeFrame';
import { loadCalendarDataInTimeframe } from './store/thunks/loadCalendarDataInTimeframe.thunk';
import { loadInitialCalendarData } from './store/thunks/loadInitialCalendarData.thunk';
import { loadInitialCalendarDataForAdmin } from './store/thunks/loadInitialCalendarDataForAdmin.thunk';
import { initMyLixaniWorkCalendar } from './store/thunks/my-lixani/initMyLixaniWorkCalendar.thunk';
import { loadPersonalWorkCalendarData } from './store/thunks/my-lixani/loadPersonalWorkCalendarData.thunk';

const initialState = {
  loading: false,
  records_demo: {},
  filePermissions_demo: filePermissionsAdapter.getInitialState(),
  assignments_demo: assignmentsAdapter.getInitialState(),
  projectsDataLoading: false,
  isPersonalWorkCalendarMode: false,
  resources: workDemoResourcesAdapter.getInitialState(),
  employers: workDemoEmployersAdapter.getInitialState(),
  projects: workDemoProjectsAdapter.getInitialState(),
  userProfileDialogData: {},
};

const workCalendarSlice = createSlice({
  name: 'workCalendar',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(initWorkCalendar.pending, (state) => ({
        ...state,
        loading: true,
        projectsDataLoading: true,
      }))
      .addCase(demoActions.resetWorkCalendar, () => ({ ...initialState }))
      .addCase(loadInitialCalendarData.fulfilled, (state, action) => {
        state.loading = false;

        filePermissionsAdapter.setMany(
          state.filePermissions_demo,
          action.payload.filePermissions
        );
        assignmentsAdapter.setMany(
          state.assignments_demo,
          action.payload.filePermissions
        );

        const resources = action.payload.resources;

        const employers = action.payload.employers;

        const projects = action.payload.projects.filter(
          (project) => !project.deletedAt
        );

        workDemoProjectsAdapter.upsertMany(state.projects, projects);
        workDemoEmployersAdapter.upsertMany(state.employers, employers);
        workDemoResourcesAdapter.upsertMany(state.resources, resources);

        state.projectsDataLoading = false;
      })
      .addCase(loadInitialCalendarDataForAdmin.fulfilled, (state, action) => {
        const resources = action.payload.resources.filter(
          (resource) => resource && resource.id
        );
        const employers = action.payload.employers.filter(
          (employer) => employer && employer.id
        );
        const projects = action.payload.externalProjects.filter(
          (project) => project && project.id && !project.deletedAt
        );

        workDemoProjectsAdapter.upsertMany(state.projects, projects);
        workDemoEmployersAdapter.upsertMany(state.employers, employers);
        workDemoResourcesAdapter.upsertMany(state.resources, resources);

        state.projectsDataLoading = false;
      })
      .addCase(loadPersonalWorkCalendarData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadPersonalWorkCalendarData.fulfilled, (state, action) => {
        state.loading = false;
        filePermissionsAdapter.setMany(
          state.filePermissions_demo,
          action.payload.filePermissions
        );
      })
      .addCase(loadPersonalWorkCalendarData.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(initMyLixaniWorkCalendar.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(initMyLixaniWorkCalendar.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(initMyLixaniWorkCalendar.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(filepermissionActions.updateFilepermission, (state, action) => {
        filePermissionsAdapter.upsertOne(state.filePermissions_demo, {
          ...action.payload,
        });
        state.updateFilepermissionLoading = false;
      })

      .addCase(initWorkCalendar.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
        projectsDataLoading: false,
      }))
      .addCase(
        loadProjectAndSubProjectAssignments.fulfilled,
        (state, action) => {
          const assignments = action.payload.filter(
            (assignment) => assignment && assignment.id
          );

          assignmentsAdapter.setMany(state.assignments_demo, assignments);
        }
      )
      .addCase(loadAssignmentsForProject.fulfilled, (state, action) => {
        const assignments = action.payload.filter(
          (assignment) => assignment && assignment.id
        );

        assignmentsAdapter.setMany(state.assignments_demo, assignments);
      })
      .addCase(loadAssignmentsForProjects.fulfilled, (state, action) => {
        const assignments = action.payload.filter(
          (assignment) => assignment && assignment.id
        );

        assignmentsAdapter.setMany(state.assignments_demo, assignments);
      })
      .addCase(initWorkCalendar.fulfilled, (state) => ({
        ...state,
        loading: false,
      }))
      .addCase(loadCalendarDataInTimeframe.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(loadCalendarDataInTimeframe.fulfilled, (state, action) => {
        state.loading = false;
        action.payload.map((projectData) => {
          filePermissionsAdapter.setMany(
            state.filePermissions_demo,
            projectData.filePermissions
          );
        });
      })
      .addCase(loadAdminCalendarDataInTimeframe.fulfilled, (state, action) => {
        state.loading = false;
        action.payload.map((data) =>
          filePermissionsAdapter.setMany(
            state.filePermissions_demo,
            data.filePermissions
          )
        );
      })
      .addCase(loadAdminCalendarDataInTimeframe.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        loadAdminCalendarDataInTimeframeWithSearchParams.pending,
        (state) => ({
          ...state,
          loading: true,
        })
      )
      .addCase(
        loadAdminCalendarDataInTimeframeWithSearchParams.fulfilled,
        (state, action) => {
          state.loading = false;
          action.payload.map((data) =>
            filePermissionsAdapter.setMany(
              state.filePermissions_demo,
              data.filePermissions
            )
          );
        }
      )
      .addCase(
        loadAdminCalendarDataInTimeframeWithSearchParams.rejected,
        (state) => ({
          ...state,
          loading: false,
        })
      )
      .addCase(loadCalendarDataInTimeframe.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
      }))
      .addCase(demoActions.setPersonalWorkCalendarMode, (state, action) => {
        state.isPersonalWorkCalendarMode = action.payload;
      })
      .addCase(loadAdminInitialDataInTimeFrame.fulfilled, (state, action) => {
        const resources = action.payload.resources.filter(
          (resource) => resource && resource.id
        );
        const employers = action.payload.employers.filter(
          (employer) => employer && employer.id
        );
        const projects = action.payload.projects.filter(
          (project) => project && project.id && !project.deletedAt
        );
        workDemoProjectsAdapter.upsertMany(state.projects, projects);
        workDemoEmployersAdapter.upsertMany(state.employers, employers);
        workDemoResourcesAdapter.upsertMany(state.resources, resources);
        filePermissionsAdapter.upsertMany(
          state.filePermissions_demo,
          action.payload.filePermissions
        );
      })
      .addCase(editRecords.fulfilled, (state, action) => {
        workDemoResourcesAdapter.setMany(
          state.resources,
          action.payload.resources
        );
      })
      .addCase(demoActions.setUserProfileDialogData, (state, action) => {
        state.userProfileDialogData = action.payload;
      })
      .addCase(demoActions.updateWorkDemoResource, (state, action) => {
        const { id, ...changes } = action.payload;
        workDemoResourcesAdapter.updateOne(state.resources, { id, changes })
      });
  },
});

export default workCalendarSlice.reducer;
