import { createAsyncThunk } from '@reduxjs/toolkit';
import { postMediaFilePermissionToProjectBank } from '../services/postMediaFilePermissionToProjectBank.service';

export const createMediaFilePermissionToProjectBank = createAsyncThunk(
  'file-permissions/project/images',
  async ({
    projectId,
    name,
    s3FileName,
    contentType,
    recordId,
    assignmentId,
    mediaTagsToAdd,
  }) => {
    const response = await postMediaFilePermissionToProjectBank(
      projectId,
      name,
      s3FileName,
      contentType,
      recordId,
      assignmentId,
      mediaTagsToAdd
    );
    console.log('response', response);
    return response;
  }
);
