import { createAsyncThunk } from '@reduxjs/toolkit';

import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { getProjectSaleInvoiceDrafts } from '../services/getProjectSaleInvoiceDrafts';

export const loadProjectSaleInvoiceDrafts = createAsyncThunk(
  'invoices/loadProjectSaleInvoiceDrafts',
  async (params, { getState, dispatch }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    const response = await getProjectSaleInvoiceDrafts(selectedProjectId);

    return response;
  }
);
