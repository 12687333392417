import { createAsyncThunk } from '@reduxjs/toolkit';
import { postUserBusinessConfiguration } from 'store/userProfile/services/post-user-business-configuration.service';

export const setUserBusinessConfigurations = createAsyncThunk(
  'business/setUserBusinessConfigurations',
  async (configuration) => {
    const configurationData = await postUserBusinessConfiguration(
      configuration
    );

    return configurationData;
  }
);
