import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectProjects } from 'store/projects/selectors/projectsSelectors';
import {
  selectAdditionalUserSalaryRows,
  selectIsReportProjectHourRowsEnabled,
  selectReportUserRowTotals,
  selectSeperateAdditionalUserSalaryRows,
} from 'store/reports/selectors/reports.selectors';

import { round } from 'lodash-es';

export const generateSalaryRows = createAsyncThunk(
  'reports/generateSalaryRows',
  async (_, { getState }) => {
    const projects = selectProjects(getState());

    const additionalUserSalaryRows = selectAdditionalUserSalaryRows(getState());

    const seperateAdditionalSalaries = selectSeperateAdditionalUserSalaryRows(
      getState()
    );

    const reportUserRowTotals = selectReportUserRowTotals(getState());

    const isReportProjectHourRowsEnabled = selectIsReportProjectHourRowsEnabled(
      getState()
    );

    let userSalaryRows = [];

    Object.values(reportUserRowTotals).forEach((row) => {
      const {
        salaryType,
        name,
        unitCount,
        userId,
        personNumber,
        projectTotals,
        totalWorkDays,
      } = row;

      if (
        isReportProjectHourRowsEnabled &&
        projectTotals &&
        salaryType === 'WORK_SALARY_HOURLY'
      ) {
        Object.entries(projectTotals).forEach(([key, value]) => {
          const project = projects[key];

          const projectReducedHours = round(value.reducedTotalHours, 2);

          userSalaryRows.push({
            userId,
            salaryType,
            unitCount: projectReducedHours,
            projectName: project.name,
            projectId: project.id,
            personName: name,
            personNumber,
            totalWorkDays,
          });
        });
      } else {
        userSalaryRows.push({
          userId,
          salaryType,
          unitCount,
          personName: name,
          personNumber,
          totalWorkDays,
        });
      }
    });
    if (isReportProjectHourRowsEnabled) {
      let projectUserIndexedTotalAdditionalSalaries = {};

      Object.values(seperateAdditionalSalaries).map((userRows) =>
        userRows.forEach((salaryRow) => {
          const {
            userId,
            salaryType,
            unitCount,
            userName,
            personNumber,
            projectId,
          } = salaryRow;

          if (
            !projectUserIndexedTotalAdditionalSalaries[
              `${userId}#${projectId}#${salaryType}`
            ]
          ) {
            projectUserIndexedTotalAdditionalSalaries[
              `${userId}#${projectId}#${salaryType}`
            ] = {
              userId,
              salaryType,
              projectId,
              unitCount,
              personName: userName,
              personNumber,
            };
          } else {
            projectUserIndexedTotalAdditionalSalaries[
              `${userId}#${projectId}#${salaryType}`
            ].unitCount += unitCount;
          }
        })
      );

      Object.values(projectUserIndexedTotalAdditionalSalaries).forEach(
        (additionalSalaryRow) => userSalaryRows.push(additionalSalaryRow)
      );
    } else {
      Object.values(additionalUserSalaryRows).map((userRows) =>
        userRows.forEach((salaryRow) => {
          const {
            userId,
            salaryType,
            unitCount,
            unitPrice,
            userName,
            personNumber,
          } = salaryRow;

          userSalaryRows.push({
            userId,
            salaryType,
            unitCount,
            unitPrice,
            personName: userName,
            personNumber,
          });
        })
      );
    }

    console.log('USER SALARY ROWS', userSalaryRows);

    return userSalaryRows;
  }
);
