import { Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setIsAfterFileUploadEditDialogOpen } from 'store/filepermissions/actions/filepermissions.actions';

export const FileUploadActionSnackbarButtons = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation('common');

  return (
    <>
      <Button
        variant="contained"
        // color="primary"
        onClick={() => dispatch(setIsAfterFileUploadEditDialogOpen(true))}
      >
        {t('gallery.add_tags')}
      </Button>
    </>
  );
};
