import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { getProjectMediaTags } from '../services/getProjectMediaTags.service';

export const loadProjectMediaTags = createAsyncThunk(
  'mediaTags/loadProjectMediaTags',
  async (projectId, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const effectiveProjectId = projectId ? projectId : selectedProjectId;

    const response = await getProjectMediaTags(effectiveProjectId);
    return response;
  }
);
