import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import Button from 'components/Button/Button';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MediaTagAutocomplete } from 'scenes/ProjectGallery/components/imagePreviewDialog/AddMediaTag/MediaTagInput/MediaTagAutoComplete';
import { selectAssignmentsByProjectId } from 'store/assignments/selectors/assignment.selectors';
import { setMediaTagToAddForFileOnUpload } from 'store/mediaTags/actions/mediaTags.actions';
import {
  selectFileUploadProjectMediaTagOptions,
  selectIsCreatingOrUpdatingTags,
  selectMediaTagsToAddByFileIndex,
  selectProjectMediaTagsByProjectId,
} from 'store/mediaTags/selectors/mediaTags.selectors';
import { createProjectMediaTagOnFileAdd } from 'store/mediaTags/thunks/createProjectMediaTagOnFileAdd.thunk';

export const ImageSelectMediaTagInput = ({ fileIndex, projectId }) => {
  const projectMediaTagsByProjectId = useSelector((state) =>
    selectProjectMediaTagsByProjectId(state, projectId)
  );

  const projectMediaTagOptions = useSelector((state) =>
    selectFileUploadProjectMediaTagOptions(state, projectId)
  );

  const [mediaTagInputValue, setMediaTagInputValue] = useState('');

  const isCreatingOrUpdatingMediaTags = useSelector(
    selectIsCreatingOrUpdatingTags
  );

  const projectAssignments = useSelector(
    selectAssignmentsByProjectId(projectId)
  );

  const mediaTagsToAddForFileIndex = useSelector((state) =>
    selectMediaTagsToAddByFileIndex(state, fileIndex)
  );

  const dispatch = useDispatch();

  const handleCreateTag = () => {
    const alreadyFoundTagWithName = projectMediaTagsByProjectId.find(
      (mediaTag) =>
        mediaTag.name.toLowerCase() === mediaTagInputValue.toLowerCase()
    );

    if (alreadyFoundTagWithName) {
      setMediaTagInputValue(alreadyFoundTagWithName.name);
      dispatch(
        setMediaTagToAddForFileOnUpload({
          fileIndex,
          mediaTag: alreadyFoundTagWithName,
        })
      );
    } else {
      dispatch(
        createProjectMediaTagOnFileAdd({ fileIndex, name: mediaTagInputValue })
      );
    }

    setTimeout(() => {
      setMediaTagInputValue('');
    }, 40);
  };

  const handleSelectMediaTag = (mediaTag) => {
    const mediaTagName = mediaTag.name;

    setMediaTagInputValue(mediaTagName);
    const foundSelectedTag = projectMediaTagsByProjectId.find(
      (mediaTag) => mediaTag.name === mediaTagName
    );

    if (foundSelectedTag) {
      dispatch(
        setMediaTagToAddForFileOnUpload({
          fileIndex,
          mediaTag: foundSelectedTag,
        })
      );
      setTimeout(() => {
        setMediaTagInputValue('');
      }, 40);
    } else {
      const foundSelectedValueFromAssignments = projectAssignments.find(
        (assignment) => assignment.name === mediaTagName
      );

      if (foundSelectedValueFromAssignments) {
        dispatch(
          createProjectMediaTagOnFileAdd({
            name: foundSelectedValueFromAssignments.name,
            assignmentId: foundSelectedValueFromAssignments.id,
            fileIndex,
          })
        );

        setTimeout(() => {
          setMediaTagInputValue('');
        }, 40);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleCreateTag();
  };

  const notAddedMediaTagOptions = projectMediaTagOptions.filter(
    (mediaTag) =>
      !mediaTagsToAddForFileIndex.some(
        (addedMediaTag) => addedMediaTag.id === mediaTag.id
      )
  );

  return (
    <form style={{ display: 'flex', width: '250px' }} onSubmit={handleSubmit}>
      <MediaTagAutocomplete
        mediaTagInputValue={mediaTagInputValue}
        mediaTagOptions={notAddedMediaTagOptions}
        handleSelectMediaTag={handleSelectMediaTag}
        setMediaTagInputValue={setMediaTagInputValue}
      ></MediaTagAutocomplete>
      <Button
        type="submit"
        disabled={!mediaTagInputValue || isCreatingOrUpdatingMediaTags}
      >
        <Icon path={mdiPlus} size={1}></Icon>
      </Button>
    </form>
  );
};
