export const PAYMENT_TERM_OPTIONS = {
  90: { name: '90 pv netto', value: 90 },
  60: { name: '60 pv netto', value: 60 },
  45: { name: '45 pv netto', value: 45 },
  35: { name: '35 pv netto', value: 35 },
  30: { name: '30 pv netto', value: 30 },
  28: { name: '28 pv netto', value: 28 },
  21: { name: '21 pv netto', value: 21 },
  14: { name: '14 pv netto', value: 14 },
  10: { name: '10 pv netto', value: 10 },
  7: { name: '7 pv netto', value: 7 },
  5: { name: '5 pv netto', value: 5 },
  0: { name: 'Heti', value: 0 },
};
