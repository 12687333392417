
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTimelinesForBusinessService } from 'scenes/Timelines/services/getTimelinesForBusiness.service';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';

export const loadAllBusinessIdTimelines = createAsyncThunk(
  'timelines/loadAllBusinessIdTimelines',
  async (_, { getState }) => {
    const selectedProject = selectSelectedProject(getState());
    let timelinesOfBusiness = [];

    let response;

    do {
      response = await getTimelinesForBusinessService(selectedProject?.business_id, selectedProject?.id, response?.lastEvaluatedKey);
      timelinesOfBusiness.push(...response.Items);
    } while (response.lastEvaluatedKey);

    const timelines = [].concat(...timelinesOfBusiness);

    return timelines;
  }
);