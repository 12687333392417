import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedEmployersBusinessIds } from 'scenes/WorkDemo/store/selectors/workSearchParamsSelectors';
import { selectSelectedEmployer } from 'store/breadcrumbs/selectors/breadcrumbs.selectors';
import {
  selectProjects,
  selectSelectedProject,
} from 'store/projects/selectors/projectsSelectors';
import { closeReportSendDialog } from '../actions/reports.actions';
import {
  selectAdditionalUserSalaryRows,
  selectIsRecordBasedReductionsActive,
  selectIsReportProjectHourRowsEnabled,
  selectReportBasicData,
  selectReportPreviewWeeklyPeriodTableData,
  selectReportUserRowTotals,
  selectRowComments,
  selectSalaryRowsRecipientEmails,
  selectSeperateAdditionalUserSalaryRows,
} from '../selectors/reports.selectors';
import { returnSentReportDataToSave } from '../utils/returnSentReportDataToSave';
import { postSalaryRowsInformation } from '../services/postSalaryRows.service';
import { getIntegrationPartnerByBusinessId } from 'store/projects/services/getIntegrationPartnerByBusinessId';
import dayjs from 'dayjs';
export const sendSalaryData = createAsyncThunk(
  'reports/sendSalaryData',
  async (integrationPartnerOverrideValue, { getState, dispatch }) => {
    const reportBasicData = selectReportBasicData(getState());

    const workCalendarSelectedBusinessIds = selectSelectedEmployersBusinessIds(
      getState()
    );

    const activeEmployerId = selectSelectedEmployer(getState());

    const projects = selectProjects(getState());

    const selectedProject = selectSelectedProject(getState());
    const recipientEmailAddresses = selectSalaryRowsRecipientEmails(getState());

    const rowComments = selectRowComments(getState());

    const navigationActiveEmployer = projects[activeEmployerId];

    const additionalUserSalaryRows = selectAdditionalUserSalaryRows(getState());

    const seperateAdditionalUserSalaryRows =
      selectSeperateAdditionalUserSalaryRows(getState());

    const reportUserRowTotals = selectReportUserRowTotals(getState());

    const weeklyTableData = selectReportPreviewWeeklyPeriodTableData(
      getState()
    );

    const isRecordBasedReductions = selectIsRecordBasedReductionsActive(
      getState()
    );

    const isReportProjectHourRowsEnabled = selectIsReportProjectHourRowsEnabled(
      getState()
    );

    let businessId;

    if (workCalendarSelectedBusinessIds[0]) {
      businessId = workCalendarSelectedBusinessIds[0];
    } else if (navigationActiveEmployer) {
      businessId = navigationActiveEmployer.business_id;
    } else {
      businessId = selectedProject.business_id;
    }

    let businessIntegrationPartner;

    if (integrationPartnerOverrideValue) {
      businessIntegrationPartner = integrationPartnerOverrideValue;
    } else {
      if (businessId) {
        businessIntegrationPartner = await getIntegrationPartnerByBusinessId(
          businessId
        );
      }
    }

    let userSalaryRows = [];

    Object.values(reportUserRowTotals).forEach((row) => {
      const {
        salaryType,
        name,
        unitCount,
        userId,
        personNumber,
        eachDayTotalHours,
        projectTotals,
      } = row;

      const rowComment = rowComments[userId];

      if (businessIntegrationPartner === 'Fennoa') {
        eachDayTotalHours.forEach((dayTotals) => {
          const { day, hours } = dayTotals;
          userSalaryRows.push({
            userId,
            salaryType,
            unitCount: hours,
            date: dayjs(day).format('DD.MM.YYYY'),
            comment: rowComment,
            personName: name,
            personNumber,
          });
        });
      } else {
        // if (!isReportProjectHourRowsEnabled) {
        userSalaryRows.push({
          userId,
          salaryType,
          unitCount,
          projectName: '',
          assignmentName: '',
          earningPeriodStartDate: reportBasicData.fromDate,
          earningPeriodEndDate: reportBasicData.toDate,
          comment: rowComment,
          personName: name,
          personNumber,
        });
        // } else {
        //   Object.entries(projectTotals).forEach(([key, value]) => {
        //     const project = projects[key];

        //     const projectReducedHours = value.reducedTotalHours;

        //     userSalaryRows.push({
        //       userId,
        //       salaryType,
        //       unitCount: projectReducedHours,
        //       projectName: project.name,
        //       projectProcountorDimension: project.procountorProjectDimensionId,
        //       earningPeriodStartDate: reportBasicData.fromDate,
        //       earningPeriodEndDate: reportBasicData.toDate,
        //       comment: rowComment,
        //       personName: name,
        //       personNumber,
        //     });
        //   });
        // }
      }
    });

    if (businessIntegrationPartner === 'Fennoa') {
      Object.values(seperateAdditionalUserSalaryRows).map((userRows) =>
        userRows.forEach((salaryRow) => {
          const { unitCount, salaryType, unitPrice, date, userId } = salaryRow;
          const rowComment =
            rowComments[`${salaryRow.userId}-${salaryRow.salaryType}`];

          const manuallyAddedUser =
            salaryRow.userId?.includes('custom-added-user');

          const manuallyAddedMainSalaryRow = Object.values(
            Object.values(reportUserRowTotals)
          ).find((userRow) => userRow.userId === salaryRow.userId);

          userSalaryRows.push({
            userId: manuallyAddedUser ? undefined : userId,
            salaryType,
            unitCount,
            unitPrice,
            date,
            comment: rowComment,
            personName: manuallyAddedUser
              ? manuallyAddedMainSalaryRow?.name
              : salaryRow.userName,
            personNumber: manuallyAddedMainSalaryRow?.personNumber,
          });
        })
      );
    } else {
      Object.values(additionalUserSalaryRows).map((userRows) =>
        userRows.forEach((salaryRow) => {
          const rowComment =
            rowComments[`${salaryRow.userId}-${salaryRow.salaryType}`];

          const manuallyAddedUser =
            salaryRow.userId.includes('custom-added-user');

          const manuallyAddedMainSalaryRow = Object.values(
            Object.values(reportUserRowTotals)
          ).find((userRow) => userRow.userId === salaryRow.userId);

          userSalaryRows.push({
            userId: manuallyAddedUser ? undefined : salaryRow.userId,
            salaryType: salaryRow.salaryType,
            unitCount: salaryRow.unitCount,
            unitPrice: salaryRow.unitPrice,
            earningPeriodStartDate: reportBasicData.fromDate,
            earningPeriodEndDate: reportBasicData.toDate,
            comment: rowComment,
            personName: manuallyAddedUser
              ? manuallyAddedMainSalaryRow?.name
              : salaryRow.userName,
            personNumber: manuallyAddedMainSalaryRow?.personNumber,
          });
        })
      );
    }

    const reportDataToSave = returnSentReportDataToSave({
      selectedProjectId: selectedProject.id,
      weeklyTableData,
      userAdditionalSalaryRows: additionalUserSalaryRows,
      reportBasicData,
      state: 'SENT',
      rowComments,
      reportUserRowTotals,
      isRecordBasedReductions,
    });

    const response = await postSalaryRowsInformation(
      {
        salaryRows: userSalaryRows,
        recipientEmailAddresses,
        reportDataToSave,
        integrationPartnerOverrideValue,
      },
      businessId
    );

    dispatch(closeReportSendDialog());

    return response;
  }
);
