import { createAsyncThunk } from '@reduxjs/toolkit';

import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import * as actions from '../actions/invoices.actions';
import { getProjectSaleInvoicesByDate } from 'store/invoices/services/getProjectSaleInvoicesByDate';

export const loadProjectSaleInvoices = createAsyncThunk(
  'invoices/loadProjectSaleInvoices',
  async (params, { getState, dispatch }) => {
    const state = getState();

    const { fromDate, toDate } = params;

    const selectedProjectId = selectSelectedProjectId(state);

    let projectSaleInvoices = [];

    let response;
    if (!fromDate || !toDate) {
      console.log('fromDate or toDate is not defined');
      return projectSaleInvoices;
    }

    do {
      response = await getProjectSaleInvoicesByDate(
        selectedProjectId,
        fromDate,
        toDate,
        response?.LastEvaluatedKey
      );
      projectSaleInvoices.push(...response.Items);

      dispatch(actions.setSaleInvoiceLoadedAmount(projectSaleInvoices.length));
    } while (response.LastEvaluatedKey);

    const filteredProjectSaleInvoices = projectSaleInvoices.filter(
      (invoice) => invoice.status !== 'DRAFT'
    );

    return filteredProjectSaleInvoices;
  }
);
