import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from '../selectors/projectsSelectors';
import { postCreateShortTermProject } from '../services/postCreateShortTermProject.service';

export const createShortTermProject = createAsyncThunk(
  'projects/createShortTermProject',
  async (data, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const projectData = data.projectData;
    const assignments = data.assignments;
    const projectCreationData = await postCreateShortTermProject(
      selectedProjectId,
      projectData,
      assignments
    );

    return projectCreationData;
  }
);
