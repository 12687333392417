import axios from 'axios';
import Config from 'Config';
import { createSignedRequest } from 'utils/createSignedRequest';

export const postSaveFilepermissionInformationForUploadedFiles = async (
  mediaTagsByFileId
) => {
  const apiPath = `file-permissions/file-information/update-file-permissions-data-after-upload`;

  const request = {
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    // url: `http://localhost:3001/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    data: { mediaTagsByFileId },
    body: JSON.stringify({ mediaTagsByFileId }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    region: Config.AWS_REGION,
    service: 'execute-api',
  };
  const signedRequest = await createSignedRequest(request);

  const response = await axios(signedRequest);
  return response.data;
};
